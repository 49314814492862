import axios from "axios";

const API_URL = `${process.env.REACT_APP_BASEURL}`;

axios.defaults.withCredentials = true

const jwtInterceptor = axios.create({
    baseURL: API_URL,
    withCredentials: true
});

jwtInterceptor.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        if (error.code === "ERR_NETWORK") {
            return Promise.reject(error);
        }
        if (error.response.status === 401) {
            await axios
                .post(`${process.env.REACT_APP_BASEURL}/api/v1/auth/refreshtoken`, {
                    withCredentials: true,
                })
                .catch((err) => {
                    return Promise.reject(err);
                });
            console.log(error.config);
            return axios(error.config);
        }
        return Promise.reject(error);
    }
);

export default jwtInterceptor;
