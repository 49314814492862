const getUser = () => {
    return JSON.parse(localStorage.getItem("user"));
};

const setUser = (user) => {
    localStorage.setItem("user", JSON.stringify(user));
};

const removeUser = () => {
    localStorage.removeItem("user");
};

const getCompanyMonogram = () => {
    return JSON.parse(localStorage.getItem("companyMonogram"));
};

const setCompanyMonogram = (companyMonogram) => {
    localStorage.setItem("companyMonogram", JSON.stringify(companyMonogram));
};

const removeCompanyMonogram = () => {
    localStorage.removeItem("companyMonogram");
};

const setCurrentProject = (project) => {
    localStorage.setItem("project", JSON.stringify(project));
}

const getCurrentProject = () => {
    return JSON.parse(localStorage.getItem("project"));
};

const removeCurrentProject = () => {
    localStorage.removeItem("project");
};

const setUserDetails = (userDetails) => {
    localStorage.setItem("userDetails", JSON.stringify(userDetails));
}

const removeUserDetails = () => {
    localStorage.removeItem("userDetails");
};

const getCompanyProjectRoleType = () => {
    return localStorage.getItem("companyProjectRoleType");
}

const setCompanyProjectRoleType = (companyProjectRoleType) => {
    localStorage.setItem("companyProjectRoleType", companyProjectRoleType);
}
const removeCompanyProjectRoleType = () => {
    localStorage.removeItem("companyProjectRoleType");
}

const LocalStorageService = {
    getUser,
    setUser,
    removeUser,
    setCompanyMonogram,
    getCompanyMonogram,
    removeCompanyMonogram,
    setCurrentProject,
    getCurrentProject,
    removeCurrentProject,
    setUserDetails,
    removeUserDetails,
    setCompanyProjectRoleType,
    removeCompanyProjectRoleType,
    getCompanyProjectRoleType
};

export default LocalStorageService;
