export const ProjectType = {
    RESIDENTIAL: 'Residential Project',
    COMMERCIAL: 'Commercial Project',
    INDUSTRIAL:'Industrial Project',
    INFRASTRUCTURE:'Infrastructure Project',
    INSTITUTIONAL:'Institutional Project',
    CIVIL:'Civil Project',
    RENOVATION:'Renovation/Remodeling Project',
    GREEN_BUILDING:'Green Building Project',
    HEAVY:'Heavy Project',
    LANDSCAPING:'Landscaping and Outdoor Project',
    MIXED_USE:'Mixed-Use Project',
    HIGH_RISE:'High-Rise Project',
    INTERIOR_FIT_OUTS:'Interior Fit-Outs Project',
    RESTORATION:'Restoration and Historic Preservation Project',
    SPECIALIZED:'Specialized Project',
};

export const ProjectStatus = {
    ACTIVE: 'Active',
    TEMPORARY_SUSPENDED: 'Temporary Suspended',
    CLOSED: 'Closed',
};

export const QuantityTypes = {
    db: 'db',
    pc: 'pc',
    m2: 'm2',
    m3: 'm3'
};

export const productStatusTypes = {
    Recorded: 'recorded',
    Ordered: 'ordered',
    OnTheWay: 'onTheWay',
    Stored: 'stored',
    Reordered: 'reordered',
    Installed: 'installed',
    Checkable: 'checkable',
    Payable: 'payable',
    Paid: 'paid',
    Done: 'done'
};

export const HungarianBusinessType = {
    Kft: 'Korlátolt Felelősségű Társaság',
    Zrt: 'Zártkörűen Működő Részvénytársaság',
    Bt: 'Betéti Társaság',
    Nyrt: 'Nyilvánosan Működő Részvénytársaság',
    Ev: 'Egyéni Vállalkozó',
    Kkt: 'Közkereseti Társaság'
};

export const CompanyProjectRoleType = {
    GENERAL_CONTRACTOR: "General Contractor",
    CONSTRUCTION_PROJECT_MANAGEMENT: "Construction Project Management",
    MATERIAL_SUPPLIER: "Material Supplier",
    DESIGNER: "Designer",
    SALES: "Sales",
};

export const CountryEnum = {
    Afghanistan: 'Afghanistan',
    Albania: 'Albania',
    Algeria: 'Algeria',
    Andorra: 'Andorra',
    Angola: 'Angola',
    AntiguaAndBarbuda: 'Antigua and Barbuda',
    Argentina: 'Argentina',
    Armenia: 'Armenia',
    Australia: 'Australia',
    Austria: 'Austria',
    Azerbaijan: 'Azerbaijan',
    Bahamas: 'Bahamas',
    Bahrain: 'Bahrain',
    Bangladesh: 'Bangladesh',
    Barbados: 'Barbados',
    Belarus: 'Belarus',
    Belgium: 'Belgium',
    Belize: 'Belize',
    Benin: 'Benin',
    Bhutan: 'Bhutan',
    Bolivia: 'Bolivia',
    BosniaAndHerzegovina: 'Bosnia and Herzegovina',
    Botswana: 'Botswana',
    Brazil: 'Brazil',
    Brunei: 'Brunei',
    Bulgaria: 'Bulgaria',
    BurkinaFaso: 'Burkina Faso',
    Burundi: 'Burundi',
    CaboVerde: 'Cabo Verde',
    Cambodia: 'Cambodia',
    Cameroon: 'Cameroon',
    Canada: 'Canada',
    CentralAfricanRepublic: 'Central African Republic',
    Chad: 'Chad',
    Chile: 'Chile',
    China: 'China',
    Colombia: 'Colombia',
    Comoros: 'Comoros',
    Congo: 'Congo',
    CostaRica: 'Costa Rica',
    Croatia: 'Croatia',
    Cuba: 'Cuba',
    Cyprus: 'Cyprus',
    CzechRepublic: 'Czech Republic',
    DemocraticRepublicOfTheCongo: 'Democratic Republic of the Congo',
    Denmark: 'Denmark',
    Djibouti: 'Djibouti',
    Dominica: 'Dominica',
    DominicanRepublic: 'Dominican Republic',
    EastTimor: 'East Timor',
    Ecuador: 'Ecuador',
    Egypt: 'Egypt',
    ElSalvador: 'El Salvador',
    EquatorialGuinea: 'Equatorial Guinea',
    Eritrea: 'Eritrea',
    Estonia: 'Estonia',
    Eswatini: 'Eswatini',
    Ethiopia: 'Ethiopia',
    Fiji: 'Fiji',
    Finland: 'Finland',
    France: 'France',
    Gabon: 'Gabon',
    Gambia: 'Gambia',
    Georgia: 'Georgia',
    Germany: 'Germany',
    Ghana: 'Ghana',
    Greece: 'Greece',
    Grenada: 'Grenada',
    Guatemala: 'Guatemala',
    Guinea: 'Guinea',
    GuineaBissau: 'Guinea-Bissau',
    Guyana: 'Guyana',
    Haiti: 'Haiti',
    Honduras: 'Honduras',
    Hungary: 'Hungary',
    Iceland: 'Iceland',
    India: 'India',
    Indonesia: 'Indonesia',
    Iran: 'Iran',
    Iraq: 'Iraq',
    Ireland: 'Ireland',
    Israel: 'Israel',
    Italy: 'Italy',
    IvoryCoast: 'Ivory Coast',
    Jamaica: 'Jamaica',
    Japan: 'Japan',
    Jordan: 'Jordan',
    Kazakhstan: 'Kazakhstan',
    Kenya: 'Kenya',
    Kiribati: 'Kiribati',
    Kosovo: 'Kosovo',
    Kuwait: 'Kuwait',
    Kyrgyzstan: 'Kyrgyzstan',
    Laos: 'Laos',
    Latvia: 'Latvia',
    Lebanon: 'Lebanon',
    Lesotho: 'Lesotho',
    Liberia: 'Liberia',
    Libya: 'Libya',
    Liechtenstein: 'Liechtenstein',
    Lithuania: 'Lithuania',
    Luxembourg: 'Luxembourg',
    Madagascar: 'Madagascar',
    Malawi: 'Malawi',
    Malaysia: 'Malaysia',
    Maldives: 'Maldives',
    Mali: 'Mali',
    Malta: 'Malta',
    MarshallIslands: 'Marshall Islands',
    Mauritania: 'Mauritania',
    Mauritius: 'Mauritius',
    Mexico: 'Mexico',
    Micronesia: 'Micronesia',
    Moldova: 'Moldova',
    Monaco: 'Monaco',
    Mongolia: 'Mongolia',
    Montenegro: 'Montenegro',
    Morocco: 'Morocco',
    Mozambique: 'Mozambique',
    Myanmar: 'Myanmar',
    Namibia: 'Namibia',
    Nauru: 'Nauru',
    Nepal: 'Nepal',
    Netherlands: 'Netherlands',
    NewZealand: 'New Zealand',
    Nicaragua: 'Nicaragua',
    Niger: 'Niger',
    Nigeria: 'Nigeria',
    NorthKorea: 'North Korea',
    NorthMacedonia: 'North Macedonia',
    Norway: 'Norway',
    Oman: 'Oman',
    Pakistan: 'Pakistan',
    Palau: 'Palau',
    Palestine: 'Palestine',
    Panama: 'Panama',
    PapuaNewGuinea: 'Papua New Guinea',
    Paraguay: 'Paraguay',
    Peru: 'Peru',
    Philippines: 'Philippines',
    Poland: 'Poland',
    Portugal: 'Portugal',
    Qatar: 'Qatar',
    Romania: 'Romania',
    Russia: 'Russia',
    Rwanda: 'Rwanda',
    SaintKittsAndNevis: 'Saint Kitts and Nevis',
    SaintLucia: 'Saint Lucia',
    SaintVincentAndTheGrenadines: 'Saint Vincent and the Grenadines',
    Samoa: 'Samoa',
    SanMarino: 'San Marino',
    SaoTomeAndPrincipe: 'Sao Tome and Principe',
    SaudiArabia: 'Saudi Arabia',
    Senegal: 'Senegal',
    Serbia: 'Serbia',
    Seychelles: 'Seychelles',
    SierraLeone: 'Sierra Leone',
    Singapore: 'Singapore',
    Slovakia: 'Slovakia',
    Slovenia: 'Slovenia',
    SolomonIslands: 'Solomon Islands',
    Somalia: 'Somalia',
    SouthAfrica: 'South Africa',
    SouthKorea: 'South Korea',
    SouthSudan: 'South Sudan',
    Spain: 'Spain',
    SriLanka: 'Sri Lanka',
    Sudan: 'Sudan',
    Suriname: 'Suriname',
    Sweden: 'Sweden',
    Switzerland: 'Switzerland',
    Syria: 'Syria',
    Taiwan: 'Taiwan',
    Tajikistan: 'Tajikistan',
    Tanzania: 'Tanzania',
    Thailand: 'Thailand',
    Togo: 'Togo',
    Tonga: 'Tonga',
    TrinidadAndTobago: 'Trinidad and Tobago',
    Tunisia: 'Tunisia',
    Turkey: 'Turkey',
    Turkmenistan: 'Turkmenistan',
    Tuvalu: 'Tuvalu',
    Uganda: 'Uganda',
    Ukraine: 'Ukraine',
    UnitedArabEmirates: 'United Arab Emirates',
    UnitedKingdom: 'United Kingdom',
    UnitedStates: 'United States',
    Uruguay: 'Uruguay',
    Uzbekistan: 'Uzbekistan',
    Vanuatu: 'Vanuatu',
    VaticanCity: 'Vatican City',
    Venezuela: 'Venezuela',
    Vietnam: 'Vietnam',
    Yemen: 'Yemen',
    Zambia: 'Zambia',
    Zimbabwe: 'Zimbabwe',
};

