import React, {useEffect, useState} from 'react';

import SearchBarAndValueHandler from "./SearchBarAndValueHandler";
import StorageLocationHandler from "./StorageLocationHandler";
import {
    getAllIncomingProductList,
    getAllCurrentlyStoredProductList,
    resetDatabase,
    getHeaderProperties
} from "./products";
import {Box, Button} from "@mui/material";
import LocalStorageService from "../new/services/localStorageService";
import {Card} from "@mui/material";


function OnSitePage() {
    const [headerParameters, setHeaderParameters] = useState([]);
    const [incomingProductData, setIncomingProductData] = useState([]);
    const [receivedProductData, setReceivedProductData] = useState([]);
    const userName = LocalStorageService.getUser().username;

    const [resultIsHided, setResultIsHided] = useState(true);

    useEffect(() => {
        fetchIncomingProductsLists();
        fetchCurrentlyStoredProductsLists();
        fetchHeaderParameters();
    }, []);

    const fetchHeaderParameters = async () => {
        try {
            const data = await getHeaderProperties();
            console.log(data.data[0]);
            setHeaderParameters(data.success ? data.data[0] : []);
        } catch (error) {
            console.log(error);
            setHeaderParameters([]);
        }
    };
    const fetchIncomingProductsLists = async () => {
        try {
            const data = await getAllIncomingProductList();
            console.log(data.data);
            setIncomingProductData(data.success ? data.data : []);
        } catch (error) {
            console.log(error);
            setIncomingProductData([]);
        }
    };
    //
    // // Map the data to rows dynamically
    // const rows = incomingProductData.map((item) => {
    //     const row = { id: item.id };
    //
    //     Object.keys(item).forEach((key) => {
    //         // if (key.includes(condition)) {
    //             row[key] = item[key];
    //         // }
    //     });
    //     // console.log(row);
    //     return row;
    // });

    // if (incomingProductData && incomingProductData.length > 0) {
    //     // Define the columns for the id properties
    //     const idColumns = Object.keys(incomingProductData[0])
    //         .filter((key) => key.includes('id'))
    //         .map((key) => ({
    //             field: key,
    //             headerName: key.toLowerCase(), // You can customize the header as needed
    //             width: 50, // Set the width as required
    //         }));
    //
    //     // Define the columns dynamically based on the properties of the first item
    //     const conditionalColumns = Object.keys(incomingProductData[0])
    //         .filter((key) => !key.includes('id'))
    //         .map((key) => ({
    //             field: key,
    //             headerName: key.toLowerCase(), // You can customize the header as needed
    //             width: key.toLowerCase().length+150, // Set the width as required
    //         }));
    //
    //     const columns = [...idColumns, ...conditionalColumns];
    //
    //     console.log(columns);
    // }


    const fetchCurrentlyStoredProductsLists = async () => {
        try {
            const data = await getAllCurrentlyStoredProductList();
            setReceivedProductData(data.success ? data.data : []);
        } catch (error) {
            console.log(error);
            setReceivedProductData([]);
        }
    };

    const fetchResetDatabase = async () => {
        await resetDatabase();
        fetchIncomingProductsLists();
        fetchCurrentlyStoredProductsLists();
        setResultIsHided(true);
    };


    // const handleIncomingMaterialList = () => {
    //     fetchIncomingMaterialsData(); // Fetch data when the button is clicked
    //     // setShowProductList(true);
    // };
    //
    // function handleReceivedMaterialList() {
    //     fetchReceivedMaterialsData();
    //     // setShowProductList(true);
    // }

    function handleResetDatabase() {
        fetchResetDatabase();
    }

    return (
        <div style={{padding: '10px'}}>
            {<SearchBarAndValueHandler
                headerParameters={headerParameters}
                incomingProductData={incomingProductData}
                receivedProductData={receivedProductData}
                setIncomingProductData={setIncomingProductData}
                setReceivedProductData={setReceivedProductData}
                userName={userName}
                fetchReceivedMaterialsData={fetchCurrentlyStoredProductsLists}
                fetchIncomingMaterialsData={fetchIncomingProductsLists}
                setResultIsHided={setResultIsHided}
                resultIsHided={resultIsHided}
            />
            }
            <div>


                {/*{storageAtInputConfirmed && (<NameInputHandler*/}
                {/*    setUserName={setUserName}*/}
                {/*    disabled={userNameInputConfirmed}*/}
                {/*    handleChange={userNameSignatureHandleChange}*/}
                {/*    handleConfirm={userNameSignatureHandleConfirm}*/}
                {/*    userName={userName}*/}
                {/*/>)}*/}
                {/*{(storageAtInputConfirmed && incomingProductData.length > 0) && <SearchBarAndValueHandler*/}

                {(incomingProductData && incomingProductData.length === 0) &&
                    (
                        <Box
                            sx={{
                                marginTop: '10px',
                                display: "flex",
                                flexDirection: 'column',
                                alignItems: "stretch",
                                justifyContent: "space-evenly",
                                flexWrap: "wrap"
                            }}>

                            {/*<div style={{textAlign: "center", paddingBottom: "10px"}}> There is no incoming products*/}
                            {/*        on the list.</div>*/}

                            <Button variant="outlined" color="error"
                                    onClick={handleResetDatabase}
                                    style={{marginBottom: '10px'}}
                            >
                                RESET HANDOVER DATABASE STATES
                            </Button>
                        </Box>)}
                    < /div>


                {/*<h2 style={{textAlign: 'center'}}>Handover List</h2>*/}
                {/*<div style={{display: 'flex', marginBottom: '10px'}}>*/}
                {/*    <input*/}
                {/*        type="text"*/}
                {/*        placeholder="Enter your name"*/}
                {/*        value={userName}*/}
                {/*        onChange={(e) => {*/}
                {/*            if (!inputConfirmed) {*/}
                {/*                setUserName(e.target.value);*/}
                {/*            }*/}
                {/*        }}*/}
                {/*        style={{padding: '5px'}}*/}
                {/*        disabled={inputConfirmed}*/}
                {/*    />*/}
                {/*    <button type="button" className="btn btn-primary" onClick={userNameSignatureHandleConfirm} style={{marginLeft: '10px'}}*/}
                {/*            disabled={userName.length === 0 || inputConfirmed}>Confirm*/}
                {/*    </button>*/}
                {/*    <button type="button" className="btn btn-primary" onClick={userNameSignatureHandleChange} style={{marginLeft: '10px'}} disabled={!inputConfirmed}>Reset</button>*/}
                {/*</div>*/}

                {/*<button type="button" className="btn btn-primary"*/}
                {/*    onClick={handleIncomingMaterialList}*/}
                {/*    style={{marginBottom: '10px'}}*/}
                {/*>*/}
                {/*    Get Incoming Materials*/}
                {/*</button>*/}

            {/*    {(storageAtInputConfirmed) && (*/}
            {/*        <Box*/}
            {/*        sx={{*/}
            {/*        marginTop: '10px',*/}
            {/*        display: "flex",*/}
            {/*        flexDirection: 'column',*/}
            {/*        alignItems: "stretch",*/}
            {/*        justifyContent: "space-evenly",*/}
            {/*        flexWrap: "wrap"*/}
            {/*    }}>*/}


            {/*    <Button variant="outlined" color="error"*/}
            {/*            onClick={handleResetDatabase}*/}
            {/*            style={{marginBottom: '10px'}}*/}
            {/*    >*/}
            {/*        RESET HANDOVER DATABASE STATES*/}
            {/*    </Button>*/}
            {/*</Box>*/}
            {/*)}*/}

            {/*{showProductList && (*/}
            {/*    <ProductList*/}
            {/*        products={incomingProductData}*/}
            {/*        onClickAccept={handleAccept}*/}
            {/*        onClickAcceptWithDamage={handleAcceptWithDamage}*/}
            {/*        onClickDecline={handleDecline}*/}
            {/*        fetchData={fetchIncomingMaterialsData}*/}
            {/*        userName={userName}*/}
            {/*        inputConfirmed={userNameInputConfirmed}*/}
            {/*    />*/}
            {/*)}*/}
        </div>
);
}


export default OnSitePage;
