import React, {Component} from 'react';
import {Container, Form, Button, Card, Alert, Row, Col} from 'react-bootstrap';
import CompanyService from "../services/company.service";
import UserService from "../services/user.service";
import LocalStorageService from "../services/localStorageService";
import {withRouter} from "../common/with-router";
import {CountryEnum, HungarianBusinessType} from "../common/enums";


class CompanyRegister extends Component {

    constructor(props) {
        super(props);
        this.state = {
            company: {
                shortName: '',
                companyNameMonogram: '',
                formOfEnterprise: '',
                street: '',
                buildingId: '',
                apartmentId: '',
                city: '',
                postalCode: '',
                country: ''
            },
            userJoinToCompanyRequest: {
                userRoleAtCompany: 'ROLE_CO_ADMIN',
                currentPositionTitleName: ''
            },
            response: {
                message: '',
                messageType: 'success'
            }
        }
    };

    handleChange = (e) => {
        const {name, value} = e.target;
        this.setState((prevState) => ({
            ...prevState,
            company: {
                ...prevState.company,
                [name]: value,
            },
        }));
    };

    handleUserRoleChange = (e) => {
        const {name, value} = e.target;
        this.setState((prevState) => ({
            ...prevState,
            userJoinToCompanyRequest: {
                ...prevState.userJoinToCompanyRequest,
                [name]: value,
            },
        }));
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const currentUser = LocalStorageService.getUser();

        setTimeout(() => {
            const serverResponse = {
                success: true,
                message: 'Company registered successfully!',
            };

            if (serverResponse.success) {
                this.setState({
                    message: serverResponse.message, messageType: 'success',
                });

                // Reset form fields
                this.setState({
                    company: {
                        shortName: '',
                        companyNameMonogram: '',
                        formOfEnterprise: '',
                        street: '',
                        buildingId: '',
                        apartmentId: '',
                        city: '',
                        postalCode: '',
                        country: '',
                    },
                    userJoinToCompanyRequest: {
                        userRoleAtCompany: 'ROLE_CO_ADMIN',
                        currentPositionTitleName: ''
                    },
                });

                // Clear the message after a specific time (e.g., 5 seconds)
                setTimeout(() => {
                    this.setState({
                        message: '', messageType: 'success', // Reset the message type
                    });
                }, 5000); // 5000 milliseconds (5 seconds)
            } else {
                this.setState({
                    message: serverResponse.message, messageType: 'danger',
                });
            }
        }, 1000);

        try {
            CompanyService.registerNewCompanyAndAddFirstUser(this.state.company, this.state.userJoinToCompanyRequest, currentUser).then(
                () => {
                    this.props.router.navigate("/my-company");
                    window.location.reload();
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    console.log("resMessage: " + resMessage);
                }
            );
        } catch (error) {
            console.error('Error:', error);
        }
        UserService.getCompanyByCurrentUserId(currentUser.id).then(
            () => { }
        );
    }

    handleBack = () => {
        this.props.router.navigate("/dashboard")
    };

    render() {
        const {company, userJoinToCompanyRequest} = this.state;

        return (<Container>
                <Card style={{minWidth: '315px'}}>
                    <Card.Header as="h1" className="text-center">
                        Company Registration
                    </Card.Header>
                    {this.state.message && (<Alert variant={this.state.messageType}
                                                   style={{marginTop: '10px'}}>{this.state.message} </Alert>)}
                    <Form onSubmit={this.handleSubmit}>
                        <Row>
                            <Col md={5}>
                                <Form.Group controlId="shortName">
                                    <Form.Label>1. Company Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="shortName"
                                        value={company.shortName}
                                        onChange={this.handleChange}
                                        required
                                        placeholder="Company Official Short Name"
                                    />
                                </Form.Group>
                            </Col>


                            <Col md={4}>
                                <Form.Group controlId="formOfEnterprise">
                                    <Form.Label>2. Form of Enterprise</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="formOfEnterprise"
                                        value={company.formOfEnterprise}
                                        onChange={this.handleChange}
                                        required
                                    >
                                        <option value="">Select Business Type</option>
                                        {Object.entries(HungarianBusinessType).map(([key]) => (
                                            <option key={key} value={key}>
                                                {key}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                            </Col>

                            <Col md={3}>
                                <Form.Group controlId="city">
                                    <Form.Label>3. City</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="city"
                                        value={company.city}
                                        onChange={this.handleChange}
                                        required
                                        placeholder="Budapest"
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>

                            <Col md={2}>
                                <Form.Group controlId="postalCode">
                                    <Form.Label>4. Postal Code</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="postalCode"
                                        value={company.postalCode}
                                        onChange={this.handleChange}
                                        placeholder="1065"
                                        required
                                    />
                                </Form.Group>
                            </Col>

                            <Col md={3}>
                                <Form.Group controlId="street">
                                    <Form.Label>5. Street Name and Type</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="street"
                                        value={company.street}
                                        onChange={this.handleChange}
                                        required
                                        placeholder="Malom utca"
                                    />
                                </Form.Group>
                            </Col>

                            <Col md={2}>
                                <Form.Group controlId="buildingId">
                                    <Form.Label>6. Building</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="buildingId"
                                        value={company.buildingId}
                                        onChange={this.handleChange}
                                        required
                                        placeholder="12/A"
                                    />
                                </Form.Group>
                            </Col>

                            <Col md={2}>
                                <Form.Group controlId="apartmentId">
                                    <Form.Label>7. Apartm.</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="apartmentId"
                                        value={company.apartmentId}
                                        onChange={this.handleChange}
                                        placeholder="25."
                                    />
                                </Form.Group>
                            </Col>

                            <Col md={3}>
                                <Form.Group controlId="country">
                                    <Form.Label>8. Country</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="country"
                                        value={company.country}
                                        onChange={this.handleChange}
                                        required
                                        placeholder="Hungary"
                                    >
                                        <option value="">Select Country</option>
                                        {Object.entries(CountryEnum).map(([key]) => (
                                            <option key={key} value={key}>
                                                {key}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <Form.Group controlId="companyNameMonogram">
                                    <Form.Label>9. Company Monogram</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="companyNameMonogram"
                                        value={company.companyNameMonogram}
                                        onChange={this.handleChange}
                                        placeholder="ex. COSN"
                                        maxLength={4}
                                        required
                                    />
                                    * Max. 4 character.
                                </Form.Group>
                            </Col>
                        </Row>

                        <Form.Group controlId="currentPositionTitleName">
                            <Form.Label>10. <b>Your</b> Role at this Company</Form.Label>
                            <Form.Control
                                type="text"
                                name="currentPositionTitleName"
                                value={userJoinToCompanyRequest.currentPositionTitleName}
                                onChange={this.handleUserRoleChange}
                                placeholder="ex. Technical Director / Assistant / Civil Engineer etc."
                                required
                            />
                        </Form.Group>

                        <div className="text-center" style={{marginTop: '10px'}}>

                            <Button
                                variant="primary"
                                type="submit"
                                style={{
                                    width: '100px',
                                    padding: '10px 0',
                                    margin: '0 5px',
                                }}
                            >
                                Register
                            </Button>

                            <Button
                                variant="secondary"
                                onClick={this.handleBack}
                                style={{
                                    width: '100px',
                                    padding: '10px 0',
                                    margin: '10px',
                                }}
                            >
                                Back
                            </Button>
                        </div>
                    </Form>
                </Card>
            </Container>
        );
    }
}

export default withRouter(CompanyRegister)

