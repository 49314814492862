import React, {useEffect, useState} from 'react';
import LocalStorageService from "../../new/services/localStorageService";
import ProductService from "../../new/services/product.service";
import {Button, ToggleButtonGroup} from "@mui/material";
import {Link} from "react-router-dom";
import CircularIndeterminateLoading from "../../components/loading/CircularIndeterminateLoading";
import ErrorPage from "../Error/ErrorPage";
import ToggleButton from "@mui/material/ToggleButton";
import DefaultDataGridModular from "./DefaultDataGridModular";
import DetailedDataGridModular from "./DetailedDataGridModular";
import EditableDataGridModular from "./EditableDataGridModular";

export default function CompanyTablePage() {
    const [companyProjectRoleTypeState, setCompanyProjectRoleTypeState] = useState('no');
    const [error, setError] = useState(null);
    const [data, setData] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [alignment, setAlignment] = React.useState('default');
    const [conditionKeyWord, setConditionKeyWord] = useState('');
    const companyProjectRoleType = LocalStorageService.getCompanyProjectRoleType();


    const handleChange = (event, newAlignment) => {
        setIsLoading(true);
        fetchData(companyProjectRoleTypeState);
        if (newAlignment === null) {
            setAlignment('default')
        } else {
            setAlignment(newAlignment);
        }

    };

    async function fetchData(companyProjectRoleType) {
        try {
            const response = await ProductService.getAllProducts(companyProjectRoleType); // Replace with
            const data = await response;
            // Process the data here
            setData(data);
            setIsLoading(false);
        } catch (error) {
            setError(error)
            if (error.message === 'Network response was not ok') {
                // Handle specific error when the response status is not OK
                console.error('The server returned an error.');
            } else if (error instanceof TypeError && error.message === 'Failed to fetch') {
                // Handle the "Failed to fetch" error
                console.error('Network request failed. Check your internet connection.');
            } else {
                // Handle other errors
                console.error('An error occurred:', error);
            }
            setIsLoading(false);
        }
    }

    useEffect(() => {
        setIsLoading(true);
        fetchData(companyProjectRoleType);
        setCompanyProjectRoleTypeState(companyProjectRoleType);

        if (companyProjectRoleType === 'GENERAL_CONTRACTOR') {
            setConditionKeyWord('gc')
        }
        if (companyProjectRoleType === 'CONSTRUCTION_PROJECT_MANAGEMENT') {
            setConditionKeyWord('pm')
        }
        if (companyProjectRoleType === 'MATERIAL_SUPPLIER') {
            setConditionKeyWord('ms')
        }

        if (companyProjectRoleType === 'DESIGNER') {
            setConditionKeyWord('ds')
        }

        if (companyProjectRoleType === 'SALES') {
            setConditionKeyWord('sl')
        }

    }, []);

    if (isLoading) {
        return (
            <>
                <CircularIndeterminateLoading/>
            </>
        )
    } else if (error) {
        return <ErrorPage/>;
    } else {
        return (
            <>
                {(data && data.length === 0) ? (
                        <>
                            <div style={{textAlign: "center"}}>
                                No data recorded jet!
                                <div style={{paddingTop: '15px'}}>
                                    {companyProjectRoleType === 'CONSTRUCTION_PROJECT_MANAGEMENT' ? (
                                        <Link to="/new-base-product"
                                              style={{textDecoration: 'none'}}>
                                            <Button color="primary" size="small">
                                                Add New Product
                                            </Button>
                                        </Link>
                                    ) : <></>}
                                </div>
                            </div>
                        </>

                    ) :
                    <div
                        style={{
                            paddingLeft: '40px',
                            paddingRight: '40px',
                            minWidth: '355px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        {alignment === 'default' && (<DefaultDataGridModular
                            products={data}
                            condition={conditionKeyWord}
                        />)}
                        {alignment === 'detailed' && (<DetailedDataGridModular
                            products={data}
                            condition={conditionKeyWord}
                        />)}
                        {alignment === 'editable' && (<EditableDataGridModular
                            products={data}
                            condition={conditionKeyWord}
                            companyProjectRoleType={companyProjectRoleTypeState}
                        />)}

                        <ToggleButtonGroup
                            color="primary"
                            value={alignment}
                            exclusive
                            onChange={handleChange}
                            aria-label="Platform"
                            sx={{mt: 1.5}}
                        >
                            <ToggleButton value="default">Default</ToggleButton>
                            <ToggleButton value="detailed">Detailed</ToggleButton>
                            <ToggleButton value="editable">Editable</ToggleButton>
                        </ToggleButtonGroup>
                    </div>}
            </>
        )
    }
}

