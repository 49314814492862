import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {Card} from "@mui/material";
import {useEffect, useState} from "react";
import ConfirmationModal from "./ConfirmationModal";
import StorageLocationHandler from "./StorageLocationHandler";

function modifyKey(key) {
    // Remove prefixes 'ms' and 'ds', and replace 'time' with a space before uppercase letters
    return key
        .replace(/^(ms|ds|pm|gc)/, '')
        .replace(/time([A-Z])/g, (match, p1) => `${p1}`)
        .replace(/([A-Z])/g, ' $1');
}

function ProductItem({
                         product,
                         onClickAccept,
                         onClickAcceptWithDamage,
                         onClickDecline,
                         collectProductInfo,
                         resetProductState,
                         isResetAllClicked,
                         storageAtInputConfirmed,
                     }) {
    const [isDisabled, setIsDisabled] = useState(false);
    const [status, setStatus] = useState('');


    useEffect(() => {
        if (isResetAllClicked) {
            setIsDisabled(false);
            setStatus('');
        }
    }, [isResetAllClicked])




    const handleAccept = () => {
        onClickAccept(product.id);
        setIsDisabled(true);
        setStatus('Accepted');
        collectProductInfo(product.id, 'Accepted');
    };

    const handleAcceptWithDamage = () => {
        onClickAcceptWithDamage(product.id);
        setIsDisabled(true);
        setStatus('Acp-with-com');
        collectProductInfo(product.id, 'Acp_Wth_Com');
    };

    const handleDecline = () => {
        onClickDecline(product.id);
        setIsDisabled(true);
        setStatus('Declined');
        collectProductInfo(product.id, 'Declined');
    };

    const handleReset = () => {
        setIsDisabled(false);
        setStatus('');
        collectProductInfo(product.id, null);
        resetProductState(product.id);
    };

    const cardClassName = `product-card ${status.toLowerCase()}`;

    return (
        <div className={cardClassName} style={{
            display: 'flex',
            flexDirection: 'column',
            border: '1px solid #ccc',
            padding: '16px',
            borderRadius: '8px',
            marginLeft: '8px',
            marginRight: '8px',
            marginBottom: '16px'
        }}>
            <h3 style={{margin: '4px 0'}}>{product.pmProductName}</h3>
            <p style={{margin: '2px 0'}}>Tech Code: {product.dsPrdTechCode}</p>
            <p style={{margin: '2px 0'}}>ID: {product.id}</p>
            <p style={{margin: '2px 0'}}>Quantity: {product.pmQty} {product.pmQtyType}</p>
            {product.gcStoredBy === null ? (
                <>
                    <Button variant="contained" style={{marginBottom: '5px'}}
                            onClick={handleAccept}
                            disabled={isDisabled || !storageAtInputConfirmed}>Accept
                    </Button>
                    <Button variant="contained" style={{marginBottom: '5px'}}
                            onClick={handleAcceptWithDamage}
                            disabled={isDisabled || !storageAtInputConfirmed}>Accept
                        with
                        Damage
                    </Button>
                    <Button variant="contained" style={{marginBottom: '5px'}}
                            onClick={handleDecline}
                            disabled={isDisabled || !storageAtInputConfirmed}>Decline
                    </Button>
                    <p style={{margin: '2px 0'}}>Status: {status}</p>
                    <Button variant="contained" onClick={handleReset}>Reset</Button>
                </>
            ) : (
                <>
                    <p style={{margin: '2px 0'}}>StoredBy: {product.gcStoredBy}</p>
                    <p style={{margin: '2px 0'}}>StoredAt: {product.gcStorage}</p>
                    <p style={{margin: '2px 0'}}>HandoverAt: {product.timeStoredAt}</p>
                </>
            )}
        </div>
    );
}

function formatDateForJavaBackend(inputDate) {
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, '0');
    const day = String(inputDate.getDate()).padStart(2, '0');
    const hour = String(inputDate.getHours()).padStart(2, '0');
    const minute = String(inputDate.getMinutes()).padStart(2, '0');
    const second = String(inputDate.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day}T${hour}:${minute}:${second}`;
}

function ProductList({
                         products,
                         onClickAccept,
                         onClickAcceptWithDamage,
                         onClickDecline,
                         fetchIncomingMaterialsData,
                         fetchReceivedMaterialsData,
                         userName,
                         storageAt,
                         userNameInputConfirmed,
                         storageAtInputConfirmed,
                         setResultIsHided,
                         showButtons,
                     }) {
    const [handOverData, setHandOverData] = useState([]);
    const [isResetAllClicked, setIsResetAllClicked] = useState(false);
    const [showReport, setShowReport] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const [requestStatus, setRequestStatus] = useState({
        loading: false,
        success: false,
        error: false,
    });

    useEffect(() => {
        if (isResetAllClicked) {
            setTimeout(() => {
                setIsResetAllClicked(false);
            }, 100);
        }
    }, [isResetAllClicked]);


    const collectProductInfo = (productId, status) => {
        const productDetails = products.find(product => product.id === productId);

        const updatedData = handOverData.map(data => {
            if (data.productId === productId) {
                return {
                    ...data,
                    ...productDetails,
                    handoverStatus: status,
                    timeOfArrivedAtSite: formatDateForJavaBackend(new Date()),
                    storedBy: userName,
                    storedAt: storageAt
                };
            }
            return data;
        });

        if (!updatedData.some(data => data.productId === productId)) {
            updatedData.push({
                productId,
                ...productDetails,
                storedBy: userName,
                storedAt: storageAt,
                timeOfArrivedAtSite: formatDateForJavaBackend(new Date()),
                handoverStatus: status
            });
        }

        setHandOverData(updatedData);
    };

    function mapStatus(input) {
        const statusMap = {
            'Accepted': 'Stored',
            'Acp_Wth_Com': 'StoredD',
            'Declined': 'Declined',
        };

        return statusMap[input] || input;
    }

    const formatCollectedInfoForBackend = () => {
        if (handOverData.length === 0 || userName === '') {
            console.log('No data to send for handover or user name not provided');
            return; // Don't send a request
        }

        // Set loading status
        setRequestStatus({loading: true, success: false, error: false});
        // setRequestStatus({loading: false, success: true, error: false});
        const formattedData = handOverData.map(item => ({
            id: item.id,
            gcStoredBy: item.storedBy,
            gcStorage: item.storedAt,
            productStatusType: mapStatus(item.handoverStatus),
            timeStorageStatusChanged: item.timeOfArrivedAtSite,
        }));
        console.log(formattedData);
        // Send formattedData to the backend using an API call

        fetch(`${process.env.REACT_APP_BASEURL}/api/v1/GENERAL_CONTRACTOR/handover`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formattedData)
        })
            .then(response => {
                if (response.ok) {
                    setRequestStatus({loading: false, success: true, error: false});
                    console.log('Handover data sent successfully');
                    // Refresh the product list
                    setHandOverData([]); // Reset the handOverData state
                    fetchIncomingMaterialsData();
                    fetchReceivedMaterialsData();
                    setResultIsHided(true);
                } else {
                    setRequestStatus({loading: false, success: false, error: true});
                    console.error('Failed to send handover data');
                }
            })
            .catch(error => {
                setRequestStatus({loading: false, success: false, error: true});
                console.error('Error while sending handover data:', error);
            });
    };

    const hideReport = () => {
        setShowReport(false);
    };

    const generateReport = () => {
        setShowReport(true)
    };

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const confirmReset = () => {
        setHandOverData([]);
        setIsResetAllClicked(true);
        const productCards = document.querySelectorAll('.product-card');
        productCards.forEach(card => {
            card.classList.remove('accepted', 'accept-with-damage', 'declined');
        });
        closeModal();
    };

    const resetAllProductStatuses = () => {
        openModal();
    };

    const resetProductState = (productId) => {
        const updatedData = handOverData.filter(data => data.productId !== productId);
        setHandOverData(updatedData);
    };

    return (
        <>
            <div>
                {products === undefined ? (
                    <p style={{textAlign: 'center', margin: '20px'}}>Loading ...</p>
                ) : (
                    <div>
                        {products.length === 0 ? (
                            <p style={{textAlign: 'center', margin: '20px'}}>This result list is empty.</p>
                        ) : (
                            <>

                                <div className="product-list-container">
                                    {products.map((product) => (
                                        <ProductItem
                                            key={product.id}
                                            product={product}
                                            onClickAccept={onClickAccept}
                                            onClickAcceptWithDamage={onClickAcceptWithDamage}
                                            onClickDecline={onClickDecline}
                                            collectProductInfo={collectProductInfo}
                                            resetProductState={resetProductState}
                                            isResetAllClicked={isResetAllClicked}
                                            userName={userName}
                                            storageAt={storageAt}
                                            userNameInputConfirmed={userNameInputConfirmed}
                                            storageAtInputConfirmed={storageAtInputConfirmed}
                                        />
                                    ))}
                                </div>
                                {showButtons && (<div className="product-list-buttons">
                                    <Button variant="contained"
                                            onClick={showReport ? hideReport : generateReport}
                                            disabled={products.length === 0}
                                    >
                                        {showReport ? "Hide Summary Table" : "Show Summary Table"}
                                    </Button>
                                    <Button variant="contained"
                                            style={{marginTop: '20px'}}
                                            onClick={resetAllProductStatuses}
                                            disabled={products.length === 0}
                                    >
                                        Reset All Card State
                                    </Button>

                                    {/* Modal */}
                                    {showModal && (
                                        <ConfirmationModal
                                            message="Are you sure?"
                                            onCancel={closeModal}
                                            onConfirm={confirmReset}
                                        />
                                    )}
                                </div>)}
                                {showReport && (
                                    <div style={{marginTop: '20px'}}>
                                        <h2 style={{textAlign: 'center'}}>Summary Table</h2>
                                        <table
                                            style={{
                                                borderCollapse: 'collapse',
                                                width: '100%',
                                                border: '1px solid black'
                                            }}>
                                            <thead>
                                            <tr>
                                                <th style={{textAlign: 'center', width: '15%'}}>Id</th>
                                                <th style={{textAlign: 'center', width: '20%'}}>Name</th>
                                                <th style={{textAlign: 'center', width: '15%'}}>Tech.Id</th>
                                                <th style={{textAlign: 'center', width: '20%'}}>Qty.</th>
                                                <th style={{textAlign: 'center', width: '30%'}}>Status</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {handOverData.map((item) => (
                                                <tr key={item.id}>
                                                    <td style={{textAlign: 'center'}}>{item.id}</td>
                                                    <td style={{textAlign: 'center'}}>{item.pmProductName}</td>
                                                    <td style={{textAlign: 'center'}}>{item.dsPrdTechCode}</td>
                                                    <td style={{textAlign: 'center'}}>{`${item.pmQty} ${item.pmQtyType}`}</td>
                                                    <td style={{textAlign: 'center'}}>{item.handoverStatus}</td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginTop: '20px',
                                    flexDirection: 'column',
                                    marginBottom: '30px'
                                }}>
                                    {showButtons && (<Button variant="contained"
                                                             onClick={formatCollectedInfoForBackend}
                                                             disabled={handOverData.length === 0 || requestStatus.loading || showModal || userName === ''}
                                                             style={{position: 'relative'}}
                                    >
                                        {requestStatus.loading ? (<>
                                                <span className="spinner-border spinner-border-sm" role="status"
                                                      aria-hidden="true"></span>
                                                SENDING...
                                            </>
                                        ) : (
                                            'FINISH HANDOVER PROCESS'
                                        )}
                                    </Button>)}
                                </div>
                            </>
                        )}
                    </div>
                )}
            </div>
        </>

    );
}

export default function SearchBarAndValueHandler({
                                                     incomingProductData,
                                                     receivedProductData,
                                                     fetchIncomingMaterialsData,
                                                     fetchReceivedMaterialsData,
                                                     userName,
                                                     resultIsHided,
                                                     setResultIsHided,
                                                     headerParameters
                                                 }) {
    const [selectAt, setSelectAt] = useState('');

    const [filteredData, setFilteredData] = useState('');
    const [valueResult, setValueResult] = useState('');
    const [showButtons, setShowButtons] = useState(true);
    const [storageAtInputConfirmed, setStorageAtInputConfirmed] = useState(false);
    const [storageAt, setStorageAt] = useState('');
    const [showProductLocationCard, setShowProductLocationCard] = useState(true);

    const state = {button: 1};

    const storageAtHandleConfirm = () => {
        setStorageAtInputConfirmed(true);
    };
    const onSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData(e.target);
        const entries = [...formData.entries()];

        const searchConditions = entries.reduce((acc, entry) => {
            const [k, v] = entry;
            acc[k] = v;
            return acc;
        }, {});
        console.log(searchConditions);
        if (state.button === 1) {
            setShowProductLocationCard(false);
            setFilteredData(incomingProductData.filter((product) => {
                const productValue = product[searchConditions.search].toString();
                const searchValue = searchConditions.value;

                return productValue === searchValue || productValue.includes(searchValue);
            }));
            setResultIsHided(false);
            setShowButtons(true);
        }
        if (state.button === 2) {
            setShowProductLocationCard(true);
            setFilteredData(receivedProductData.filter((product) => {
                const productValue = product[searchConditions.search].toString();
                const searchValue = searchConditions.value;

                return productValue === searchValue || productValue.includes(searchValue);
            }));
            setResultIsHided(false);
            setShowButtons(false);
        }
    };
    const handleChange = (event) => {
        setSelectAt(event.target.value);
    };

    const handleClear = () => {
        setSelectAt('');
        setResultIsHided(true);
        setValueResult('');
        setStorageAtInputConfirmed(false);
        setStorageAt('');
        setShowProductLocationCard(true);
    }


    const handleAccept = () => {
        // Logic to handle accept for the specific product
    };

    const handleAcceptWithDamage = () => {
        // Logic to handle accept with damage for the specific product
    };

    const handleDecline = () => {
        // Logic to handle decline for the specific product
    };

    return (
        <>
            <Card
                sx={{
                    display: "flex",
                    flexDirection: 'column',
                    alignItems: "flex-start",
                    maxWidth: "550px",
                    padding: "15px",
                    p: 2,
                    py: 2,
                    margin: "10px auto",
                    gap: 2,
                    borderRadius: "15px",
                    boxShadow: 1
                }}
                component="form"
                onSubmit={onSubmit}
                elevation={10}
            >
                <div><b>1. Set Parameters:</b></div>
                <FormControl sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'row',
                    paddingLeft: '10px',
                    paddingRight: '10px'
                }} fullWidth>
                    {/*<Box sx={{display: 'flex', justifyContent: 'center'}}>*/}
                    <FormControl sx={{minWidth: '50%'}}>
                        <InputLabel id="demo-simple-select-label">Search At</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            name="search"
                            id="search"
                            value={selectAt}
                            onChange={handleChange}
                            required
                        >
                            {headerParameters && Object.entries(headerParameters).map(([key]) => (
                                <MenuItem key={key} value={key}>
                                    {modifyKey(key)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ml: 2, minWidth: '50%'}}>
                        <TextField
                            name="value"
                            id="value"
                            placeholder={"Value"}
                            required
                            value={valueResult} // Bind value to component state
                            onChange={(e) => setValueResult(e.target.value)} // Update state on change
                        />
                    </FormControl>
                    {/*</Box>*/}
                </FormControl>
                <div style={{display: "flex", width: '100%'}}>
                    <Button
                        sx={{marginRight: "0.5rem"}}
                        variant="contained"
                        type="submit"
                        onClick={() => (state.button = 1)}
                    >
                        Get ORDERED && ONTHEWAY MATERIALS
                    </Button>
                    <Button
                        sx={{marginRight: "0.5rem"}}
                        variant="outlined"
                        type="submit"
                        onClick={() => (state.button = 2)}
                    >
                        GET CURRENTLY STORED MATERIALS
                    </Button>
                    <Button
                        variant="contained" color="warning" onClick={handleClear}>
                        Reset
                    </Button>
                </div>
            </Card>

            {(!showProductLocationCard && incomingProductData && incomingProductData.length > 0 && filteredData.length !== 0 ) && (!storageAtInputConfirmed ? <StorageLocationHandler
                storageAt={storageAt}
                disabled={storageAtInputConfirmed}
                setStorageAt={setStorageAt}
                handleConfirm={storageAtHandleConfirm}
            /> : <Card sx={{
                display: "flex",
                flexDirection: 'column',
                alignItems: "flex-start",
                maxWidth: "550px",
                padding: "15px",
                p: 2,
                py: 2,
                // maxWidth: "600px",
                margin: "10px auto",
                gap: 2,
                borderRadius: "15px",
                boxShadow: 1
            }} elevation={10}
            >

                <div><b> 2. Products will be store at: </b> {storageAt}</div>
            </Card>)}

            <h4 style={{textAlign: 'center', marginBottom: '20px', marginTop: '20px'}}>Handover Product List
                Result</h4>

            <Box sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
                flexWrap: "wrap"
            }}>
                {!resultIsHided ? filteredData.length ?
                    (<ProductList
                        products={filteredData}
                        onClickAccept={handleAccept}
                        onClickAcceptWithDamage={handleAcceptWithDamage}
                        onClickDecline={handleDecline}
                        fetchIncomingMaterialsData={fetchIncomingMaterialsData}
                        fetchReceivedMaterialsData={fetchReceivedMaterialsData}
                        userName={userName}
                        storageAt={storageAt}
                        setStorageAt={setStorageAt}
                        storageAtInputConfirmed={storageAtInputConfirmed}
                        setResultIsHided={setResultIsHided}
                        showButtons={showButtons}
                    />)
                    : "No results with these parameters!" : "Please specify the parameters!"}
                {/*)}*/}
            </Box>
        </>
    );
// }
};
