import jwtInterceptor from "./jwtInterceptor";

const API_URL = `${process.env.REACT_APP_BASEURL}/api/v1/test/`;

const getPublicContent = () => {
    return jwtInterceptor.get(API_URL + 'all');
}

const getUserBoard = () => {
    return jwtInterceptor.get(API_URL + 'user');
}

const getProjectAdminBoard = () => {
    return jwtInterceptor.get(API_URL + 'projectadmin');
}

const getCompanyAdminBoard = () => {
    return jwtInterceptor.get(API_URL + 'companyadmin');
}
const getAdminBoard = () => {
    return jwtInterceptor.get(API_URL + 'admin');
}

const TestService = {
    getPublicContent,
    getUserBoard,
    getAdminBoard,
    getProjectAdminBoard,
    getCompanyAdminBoard
}

export default TestService;
