import React, {Component} from "react";
import {Navigate} from "react-router-dom";
import LocalStorageService from "../services/localStorageService";
import ProjectService from "../services/project.service";
import UserService from "../services/user.service";

export default class MyProject extends Component {
    constructor(props) {
        super(props);

        this.state = {
            redirect: null,
            userReady: false,
            currentUser: {username: ""},
            projectName: {projectName: ""},
            project: {project: ""}
        };
    }

    componentDidMount() {
        const currentUser = LocalStorageService.getUser();
        const projectName = LocalStorageService.getCurrentProject();

        try {
            ProjectService.getProjectByMonogram(projectName.projectMonogram).then(
                (res) => {
                    if (!currentUser) this.setState({redirect: "/home"});
                    this.setState({
                        currentUser: currentUser,
                        userReady: true,
                        projectName: res.projectMonogram,
                        project: res
                    })
                }
            );
        } catch (err) {
            console.log(err)
        }
        UserService.getCompanyByCurrentUserId(currentUser.id);
    }

    render() {

        if (this.state.redirect) {
            return <Navigate to={this.state.redirect}/>
        }

        const {projectName, project} = this.state;

        return (
            <div className="container" style={{paddingBottom: '30px'}}>
                {(this.state.userReady) ?
                    <div>
                        <header className="jumbotron">
                            <h3>
                                <strong>{projectName}</strong> Project Profile
                            </h3>
                        </header>
                        <p>
                            <strong>Id:</strong>{" "}
                            {project.id}
                        </p>
                        <p>
                            <strong>Name:</strong>{" "}
                            {project.projectName} ({project.projectMonogram})
                        </p>

                        <p>
                            <strong>Status:</strong>{" "}
                            {project.projectStatus}
                        </p>

                        <p>
                            <strong>Project Type:</strong>{" "}
                            {project.projectType}
                        </p>
                        <strong>Project Address:</strong>
                        <ul>
                            <li>
                                <strong>Street:</strong>{" "}
                                {project.street}
                            </li>
                            <li>
                                <strong>Building:</strong>{" "}
                                {project.buildingId}
                            </li>
                            <li>
                                <strong>City:</strong>{" "}
                                {project.city}
                            </li>
                            <li>
                                <strong>Postal Code:</strong>{" "}
                                {project.postalCode}
                            </li>
                            <li>
                                <strong>Country:</strong>{" "}
                                {project.country}
                            </li>
                            {project.hasOfficialAddress ? null : (
                                <>
                                    <li>
                                        <strong>Plot Number:</strong>{" "}
                                        {project.plotNumber}
                                    </li>
                                    <li>
                                        <strong>Additional Info:</strong>{" "}
                                        {project.additionalInfoToGetThere}
                                    </li>
                                </>)}
                        </ul>
                        <strong>Companies:</strong>
                        <ul>
                          {project.companies && project.companies.length > 0 ? (
                              project.companies.map((company, index) => (
                                  <li key={index}>
                                    <b>{company.companyMonogram}</b> as {company.companyProjectRoleType}
                                  </li>
                              ))
                          ) : (
                              <li> This company is currently not participating in any projects. </li>
                          )}
                        </ul>
                    </div> : null}
            </div>
        );
    }
}
