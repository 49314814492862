// import React, { Component } from 'react';
// import 'font-awesome/css/font-awesome.min.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import axios from 'axios';
//
// class HomePage extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             timeLeft: { minutes: 0, seconds: 5 },
//             status: 'Server is not running',
//             requestSent: false,
//             timerRunning: false,
//             progressPercentage: 0,
//         };
//     }
//
//     formatTime = (time) => {
//         return `${String(time.minutes).padStart(2, '0')}:${String(time.seconds).padStart(2, '0')}`;
//     };
//
//     handleCheckClick = () => {
//         // Reset the timer to the initial time
//         this.setState({
//             timeLeft: { minutes: 0, seconds: 5 },
//             status: 'Server is not running',
//             requestSent: false,
//             progressPercentage: 0,
//         });
//
//         // Start the timer
//         this.setState({ timerRunning: true });
//         this.sendGetRequest();
//     };
//
//     sendGetRequest = () => {
//         axios
//             .get('http://localhost:8080/check')
//             .then((response) => {
//                 console.log(response.status);
//                 if (response.status === 200) {
//                     this.setState({
//                         status: 'Server is working',
//                         progressPercentage: 100,
//                         timeLeft: { minutes: 0, seconds: 0 },
//                         timerRunning: false,
//                     });
//                 }
//             })
//             .catch((error) => {
//                 // This catch block will capture the error but take no action
//             });
//     };
//
//     componentDidMount() {
//         this.sendGetRequest();
//         this.timerInterval = setInterval(() => {
//             const { timerRunning, timeLeft } = this.state;
//             const totalSeconds = 0 * 60 + 5;
//             const remainingSeconds = timeLeft.minutes * 60 + timeLeft.seconds;
//
//             if (timerRunning && timeLeft.minutes === 0 && timeLeft.seconds === 0) {
//                 clearInterval(this.timerInterval);
//                 this.setState({
//                     status: 'Something went wrong ...',
//                     progressPercentage: 100,
//                 });
//                 return;
//             }
//
//             if (timerRunning) {
//                 this.setState({ status: 'Server is building up ...' });
//                 if (timeLeft.seconds === 0) {
//                     this.setState({ timeLeft: { minutes: timeLeft.minutes - 1, seconds: 59 } });
//                 } else {
//                     this.setState({ timeLeft: { minutes: timeLeft.minutes, seconds: timeLeft.seconds - 1 } });
//                 }
//
//                 if (timeLeft.seconds % 2 === 0) {
//                     this.sendGetRequest();
//                 }
//                 this.setState({
//                     progressPercentage: Math.ceil(((totalSeconds - remainingSeconds) / totalSeconds) * 100),
//                 });
//             }
//         }, 1000);
//     }
//
//     componentWillUnmount() {
//         clearInterval(this.timerInterval);
//     }
//
//     render() {
//         const { timeLeft, status, requestSent, progressPercentage } = this.state;
//
//         let statusIcon;
//         let statusBarClass = 'status-bar';
//         let progressBarType = '';
//         let progressBarAnimated = '';
//         let buttonText = 'Start Server';
//         let hideButton1 = false;
//         let hideButton2 = true;
//         let hideProgressBar = true;
//         let buttonType = 'btn btn-primary';
//
//         if (status === 'Server is building up ...') {
//             statusIcon = <i className="fa spinner-border text-primary" />;
//             statusBarClass += ' building-up';
//             progressBarType = '';
//             hideButton1 = true;
//             hideProgressBar = false;
//         } else if (status === 'Server is working') {
//             statusIcon = <i className="fa fa-check-circle fa-2x green-icon" />;
//             progressBarType = 'bg-success';
//             progressBarAnimated = 'progress-bar-animated';
//             hideButton1 = false;
//             hideButton2 = false;
//             buttonType = 'btn btn-success';
//             buttonText = 'Login';
//         } else if (status === 'Server is not running') {
//             statusIcon = <i className="fa fa-exclamation-triangle fa-2x yellow-icon" />;
//         } else if (status === 'Something went wrong ...') {
//             statusIcon = <i className="fa fa-exclamation-triangle fa-2x yellow-icon" />;
//         }
//
//         return (
//             <div className="App">
//
//                 <h1>Server Status</h1>
//                 <div className="timer">
//                     <p>{this.formatTime(timeLeft)}</p>
//                 </div>
//
//                 <div className={statusBarClass} style={{ padding: '20px' }}>
//                     <div className="d-flex align-items-center">
//                         <strong>{status}</strong>
//                         <div className="ml-auto" role="status" aria-hidden="true">
//                             {statusIcon}
//                         </div>
//                     </div>
//                     <div className="progress" style={{ height: '15px' }} hidden={hideProgressBar}>
//                         <div
//                             className={`progress-bar progress-bar-striped ${progressBarType} ${progressBarAnimated}`}
//                             role="progressbar"
//                             style={{ width: `${progressPercentage}%` }}
//                             aria-valuenow={progressPercentage}
//                             aria-valuemin="0"
//                             aria-valuemax="100"
//                         >
//                             {`${progressPercentage}%`}
//                         </div>
//                     </div>
//                 </div>
//                 <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'space-evenly' }}>
//                     <button
//                         type="button"
//                         className={buttonType}
//                         onClick={this.handleCheckClick}
//                         disabled={requestSent}
//                         hidden={hideButton1}
//                         style={{ width: '120px' }}
//                     >
//                         {requestSent ? 'Server is building up...' : buttonText}
//                     </button>
//                     {!hideButton2 ? (
//                         <button type="button" className={buttonType} disabled={requestSent} style={{ width: '120px' }}>
//                             Sign Up
//                         </button>
//                     ) : null}
//                 </div>
//             </div>
//         );
//     }
// }
//
// export default HomePage;


import React, {useState, useEffect} from 'react';
import 'font-awesome/css/font-awesome.min.css';
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";


function HomePage() {
    const [timeLeft, setTimeLeft] = useState({minutes: 2, seconds: 40});
    const [status, setStatus] = useState('Server is not working!');
    const [timerRunning, setTimerRunning] = useState(false);


    // Calculate the progress percentage based on the time left
    const totalSeconds = 2 * 60 + 40; // 2 minutes and 30 seconds
    const remainingSeconds = timeLeft.minutes * 60 + timeLeft.seconds;
    const [progressPercentage, setProgressPercentage] = useState((Math.ceil(((totalSeconds - remainingSeconds) / totalSeconds) * 100))); // Track errors

    const handleCheckClick = () => {
        setTimerRunning(true);
        sendGetRequest();
        setTimeLeft({minutes: 2, seconds: 40})
    };

    const sendGetRequest = () => {

        axios.get(`${process.env.REACT_APP_BASEURL}/api/v1/check`)
            .then((response) => {
                if (response.status === 200) {
                    setStatus('Server is working');
                    setProgressPercentage(100);
                    setTimeLeft({minutes: 0, seconds: 0})
                    setTimerRunning(false);
                }
            })
            .catch((error) => {
                // This catch block will capture the error but take no action
            });
    };

    useEffect(() => {
        let timerInterval;
        if (timerRunning && timeLeft.minutes === 0 && timeLeft.seconds === 0) {
            clearInterval(timerInterval);
            setStatus('Something went wrong!');
            setProgressPercentage(100);
            return;
        }

        if (timerRunning) {
            setStatus('Server is building up');
            timerInterval = setInterval(() => {
                if (timeLeft.seconds === 0) {
                    setTimeLeft({minutes: timeLeft.minutes - 1, seconds: 59});
                } else {
                    setTimeLeft({minutes: timeLeft.minutes, seconds: timeLeft.seconds - 1});
                }

                if (timeLeft.seconds % 40 === 0) {
                    sendGetRequest();
                }
            }, 1000);
            setProgressPercentage((Math.ceil(((totalSeconds - remainingSeconds) / totalSeconds) * 100)))
        }

        return () => {
            clearInterval(timerInterval);
        };
    }, [timerRunning, timeLeft, totalSeconds, remainingSeconds]);

    useEffect(() => {
        // Send an initial GET request when the component is mounted
        sendGetRequest();
    }, []);

    let statusIcon;
    let statusBarClass = 'status-bar';
    let progressBarType = '';
    let progressBarAnimated = '';
    let buttonText = 'Start Server';
    let hideButton1 = false;
    let buttonType = 'btn btn-primary';
    let hideProgressBar = true;
    let instruction = <>Please click the button below to <b>start</b> it! It takes approximately <b>2
        minutes</b> and <b>30
        seconds</b>.</>

    if (status === 'Server is building up') {
        // statusIcon = <i className="fa spinner-border text-primary"/>;
        statusBarClass += ' building-up';
        progressBarType = '';
        hideButton1 = true;
        hideProgressBar = false;
        instruction = <>The server is building up, thank you for your patience!</>;
    } else if (status === 'Server is working') {
        statusIcon = <i className="fa fa-check-circle green-icon"/>;
        progressBarType = 'bg-success';
        progressBarAnimated = 'progress-bar-animated';
        hideButton1 = true;
        buttonType = 'btn btn-success';
        buttonText = 'Login';
        hideProgressBar = false;
        instruction = <>
            If you are reading this, it means the server is currently working.
        </>;
    } else if (status === 'Server is not working!') {
        instruction = <>Please click the button below to <b>start</b> it! It takes approximately <b>2
            minutes</b> and <b>30
            seconds</b>.</>
        statusIcon = <i className="fa fa-exclamation-triangle yellow-icon"/>;
    } else if (status === 'Something went wrong') {
        statusIcon = <i className="fa fa-exclamation-triangle yellow-icon"/>;
        instruction = <>Please click the button below to <b>start</b> it! It takes approximately <b>2
            minutes</b> and <b>30
            seconds</b>.</>
        buttonText = 'Try Again'
        hideProgressBar = false;
    }

    return (
        <>
            <div className="App">
                <div className="HomePage" style={{paddingTop: '40px'}}>
                    <h1><b>Welcome!</b></h1>
                    <div className="message" style={{paddingBottom: '10px'}}>
                        This website is hosted at <b>render.com</b> by using free plan, which means the server isn't
                        always running.
                    </div>

                    <div className="message">
                        {instruction}
                    </div>
                    <div className={statusBarClass}>
                        <div className="align-items-center" style={{textAlign: 'center', paddingBottom: '10px'}}>
                            {/*<div className="mr-auto" role="status" aria-hidden="true">{statusIcon}</div>*/}
                            <strong>{statusIcon} {status} {statusIcon}</strong>

                        </div>
                        <div className="progress" style={{height: '10px', backgroundColor: '#c2c2c2'}}>
                            <div
                                className={`progress-bar progress-bar-striped ${progressBarType} ${progressBarAnimated}`}
                                role="progressbar"
                                style={{width: `${progressPercentage}%`}}
                                aria-valuenow={progressPercentage}
                                aria-valuemin="0"
                                aria-valuemax="100"
                                hidden={hideProgressBar}
                            >
                                {`${progressPercentage}%`}
                            </div>
                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        justifyContent: 'space-evenly'
                    }}>
                    </div>
                    <div style={{paddingTop: '20px'}}>
                        <button type="button" className={buttonType} onClick={handleCheckClick}
                                hidden={hideButton1} style={{width: '120px'}}>
                            {buttonText}
                        </button>
                    </div>
                </div>
                <div className="message" style={{textAlign: 'center'}}>
                    Feel free to register <b>Yourself</b>, or you just use the following <b>Test USER name</b>'s and <b>password</b>'s:
                </div>
                <div style={{paddingTop: '10px', overflowX: 'auto'}}>
                    <table border={1} className="table table-striped" style={{marginLeft: 'auto', marginRight: 'auto', borderColor: '#dde0e3', width: '30%', textAlign: 'left'}}>
                        <thead>
                        <tr>
                            <th style={{padding: '0.3rem'}}>nr.</th>
                            <th style={{padding: '0.3rem'}}>username</th>
                            <th style={{padding: '0.3rem'}}>password</th>
                            <th style={{padding: '0.3rem'}}>AppUser ROLE</th>
                            {/*<th style={{padding: '0.3rem'}}>CompanyUser ROLE</th>*/}
                            {/*<th style={{padding: '0.3rem'}}>ProjectUser ROLE</th>*/}
                            {/*<th style={{padding: '0.3rem'}}>Company Name</th>*/}
                            {/*<th style={{padding: '0.3rem'}}>Project Name</th>*/}
                            <th style={{padding: '0.3rem'}}>[CO-PR] Relation</th>
                            {/*<th style={{padding: '0.3rem'}}>Office</th>*/}
                            {/*<th style={{padding: '0.3rem'}}>OnSite</th>*/}
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td style={{padding: '0.3rem'}}>1</td>
                            <td style={{padding: '0.3rem'}}>adminApp</td>
                            <td style={{padding: '0.3rem'}}>ad</td>
                            <td style={{padding: '0.3rem'}}>admin</td>
                            {/*<td style={{padding: '0.3rem'}}>CO_MEMBER</td>*/}
                            {/*<td style={{padding: '0.3rem'}}>PR_MEMBER</td>*/}
                            {/*<td style={{padding: '0.3rem'}}>BAU</td>*/}
                            {/*<td style={{padding: '0.3rem'}}>RIV</td>*/}
                            <td style={{padding: '0.3rem'}}>GC1</td>
                            {/*<td style={{padding: '0.3rem'}}>X</td>*/}
                            {/*<td style={{padding: '0.3rem'}}>X</td>*/}
                        </tr>
                        <tr>
                            <td style={{padding: '0.3rem'}}>2</td>
                            <td style={{padding: '0.3rem'}}>userPm1</td>
                            <td style={{padding: '0.3rem'}}>ad</td>
                            <td style={{padding: '0.3rem'}}>user</td>
                            {/*<td style={{padding: '0.3rem'}}>CO_MEMBER</td>*/}
                            {/*<td style={{padding: '0.3rem'}}>PR_MEMBER</td>*/}
                            {/*<td style={{padding: '0.3rem'}}>LEAN</td>*/}
                            {/*<td style={{padding: '0.3rem'}}>RIV</td>*/}
                            <td style={{padding: '0.3rem'}}>PM</td>
                            {/*<td style={{padding: '0.3rem'}}>X</td>*/}
                            {/*<td style={{padding: '0.3rem'}}>NA</td>*/}
                        </tr>
                        <tr>
                            <td style={{padding: '0.3rem'}}>3</td>
                            <td style={{padding: '0.3rem'}}>userPm2</td>
                            <td style={{padding: '0.3rem'}}>ad</td>
                            <td style={{padding: '0.3rem'}}>user</td>
                            {/*<td style={{padding: '0.3rem'}}>CO_ADMIN</td>*/}
                            {/*<td style={{padding: '0.3rem'}}>PR_MEMBER</td>*/}
                            {/*<td style={{padding: '0.3rem'}}>LEAN</td>*/}
                            {/*<td style={{padding: '0.3rem'}}>RIV</td>*/}
                            <td style={{padding: '0.3rem'}}>PM</td>
                            {/*<td style={{padding: '0.3rem'}}>X</td>*/}
                            {/*<td style={{padding: '0.3rem'}}>NA</td>*/}
                        </tr>
                        <tr>
                            <td style={{padding: '0.3rem'}}>4</td>
                            <td style={{padding: '0.3rem'}}>userGC</td>
                            <td style={{padding: '0.3rem'}}>ad</td>
                            <td style={{padding: '0.3rem'}}>user</td>
                            {/*<td style={{padding: '0.3rem'}}>CO_ADMIN</td>*/}
                            {/*<td style={{padding: '0.3rem'}}>PR_MEMBER</td>*/}
                            {/*<td style={{padding: '0.3rem'}}>BAU</td>*/}
                            {/*<td style={{padding: '0.3rem'}}>RIV</td>*/}
                            <td style={{padding: '0.3rem'}}>GC1</td>
                            {/*<td style={{padding: '0.3rem'}}>X</td>*/}
                            {/*<td style={{padding: '0.3rem'}}>X</td>*/}
                        </tr>
                        <tr>
                            <td style={{padding: '0.3rem'}}>5</td>
                            <td style={{padding: '0.3rem'}}>userSales</td>
                            <td style={{padding: '0.3rem'}}>ad</td>
                            <td style={{padding: '0.3rem'}}>user</td>
                            {/*<td style={{padding: '0.3rem'}}>CO_ADMIN</td>*/}
                            {/*<td style={{padding: '0.3rem'}}>PR_MEMBER</td>*/}
                            {/*<td style={{padding: '0.3rem'}}>TOP</td>*/}
                            {/*<td style={{padding: '0.3rem'}}>RIV</td>*/}
                            <td style={{padding: '0.3rem'}}>Sales</td>
                            {/*<td style={{padding: '0.3rem'}}>X</td>*/}
                            {/*<td style={{padding: '0.3rem'}}>NA</td>*/}
                        </tr>
                        <tr>
                            <td style={{padding: '0.3rem'}}>6</td>
                            <td style={{padding: '0.3rem'}}>userSupplier</td>
                            <td style={{padding: '0.3rem'}}>ad</td>
                            <td style={{padding: '0.3rem'}}>user</td>
                            {/*<td style={{padding: '0.3rem'}}>CO_ADMIN</td>*/}
                            {/*<td style={{padding: '0.3rem'}}>PR_MEMBER</td>*/}
                            {/*<td style={{padding: '0.3rem'}}>TRA</td>*/}
                            {/*<td style={{padding: '0.3rem'}}>RIV</td>*/}
                            <td style={{padding: '0.3rem'}}>Supplier</td>
                            {/*<td style={{padding: '0.3rem'}}>X</td>*/}
                            {/*<td style={{padding: '0.3rem'}}>NA</td>*/}
                        </tr>
                        <tr>
                            <td style={{padding: '0.3rem'}}>7</td>
                            <td style={{padding: '0.3rem'}}>userDesigner</td>
                            <td style={{padding: '0.3rem'}}>ad</td>
                            <td style={{padding: '0.3rem'}}>user</td>
                            {/*<td style={{padding: '0.3rem'}}>CO_ADMIN</td>*/}
                            {/*<td style={{padding: '0.3rem'}}>PR_MEMBER</td>*/}
                            {/*<td style={{padding: '0.3rem'}}>PLN</td>*/}
                            {/*<td style={{padding: '0.3rem'}}>RIV</td>*/}
                            <td style={{padding: '0.3rem'}}>Designer</td>
                            {/*<td style={{padding: '0.3rem'}}>X</td>*/}
                            {/*<td style={{padding: '0.3rem'}}>NA</td>*/}
                        </tr>
                        <tr>
                            <td style={{padding: '0.3rem'}}>8</td>
                            <td style={{padding: '0.3rem'}}>userOther</td>
                            <td style={{padding: '0.3rem'}}>ad</td>
                            <td style={{padding: '0.3rem'}}>user</td>
                            {/*<td style={{padding: '0.3rem'}}>CO_ADMIN</td>*/}
                            {/*<td style={{padding: '0.3rem'}}>PR_ADMIN</td>*/}
                            {/*<td style={{padding: '0.3rem'}}>OTH</td>*/}
                            {/*<td style={{padding: '0.3rem'}}>M2A</td>*/}
                            <td style={{padding: '0.3rem'}}>GC2</td>
                            {/*<td style={{padding: '0.3rem'}}>X</td>*/}
                            {/*<td style={{padding: '0.3rem'}}>X</td>*/}
                        </tr>
                        </tbody>
                    </table>
                </div>
                {/*<div className="table-responsive-sm">*/}
                {/*    <table className="table">*/}
                {/*        <thead>*/}
                {/*        <tr>*/}
                {/*            <th>nr.</th>*/}
                {/*            <th>username</th>*/}
                {/*            <th>password</th>*/}
                {/*        </tr>*/}
                {/*        </thead>*/}
                {/*        <tbody>*/}
                {/*        <tr>*/}
                {/*            <td>1</td>*/}
                {/*            <td>adminAp</td>*/}
                {/*            <td>ad</td>*/}
                {/*        </tr>*/}
                {/*        <tr>*/}
                {/*            <td>2</td>*/}
                {/*            <td>userWithCompany</td>*/}
                {/*            <td>ad</td>*/}
                {/*        </tr>*/}
                {/*        <tr>*/}
                {/*            <td>3</td>*/}
                {/*            <td>userWithCoAndPr</td>*/}
                {/*            <td>ad</td>*/}
                {/*        </tr>*/}
                {/*        <tr>*/}
                {/*            <td>4</td>*/}
                {/*            <td>justUser</td>*/}
                {/*            <td>ad</td>*/}
                {/*        </tr>*/}
                {/*        </tbody>*/}
                {/*    </table>*/}
                {/*</div>*/}
            </div>
        </>
    );
}

export default HomePage;
