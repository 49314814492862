import React from 'react';

const ItemCard = ({ keyId, itemName, itemFrom, monogram, city, joinStatus, loading, has, openModal,
                      optionalInfo
}) => {
    return (
        <div className="card mb-3">
            <div className="card-body">
                <h5 className="card-title">
                    {itemName} [{itemFrom}]
                </h5>
                <p className="card-subtitle mb-2 text-muted">{monogram}</p>
                <p className="card-subtitle mb-2 text-muted">
                    {city}
                </p>
                <p className="card-text">
                    {optionalInfo ? (optionalInfo > 1 ? (<>{optionalInfo} Members</>) : (<>{optionalInfo} Member</>)) : ('')}
                </p>
                {!has ? (<button
                    className="btn btn-primary"
                    onClick={() => {
                        openModal(keyId,itemName, itemFrom);
                        // onJoinClick(company.id);
                    }}
                    disabled={joinStatus === 'joining' || loading} // Disable when joining or loading
                >
                    {joinStatus === 'joining' ? 'Joining...' : 'Join'}
                </button>) : null}
            </div>
        </div>
    );
};

export default ItemCard;
