
import Login from "./new/components/login.component";
import Profile from "./new/components/user-profile.component";
import DashBoard from "./new/components/dashboard.component";
import ProjectAdminBoard from "./new/components/board-projectAdmin.component";
import BoardAdmin from "./new/components/board-admin.component";
import Signup from "./new/components/signup.component";

import CompanyAdminBoard from "./new/components/board-companyAdmin.component";
import Session from "./new/components/session.component";
import CompanyRegister from "./new/components/company-register.component";
import MyCompany from "./new/components/company-profile.component";
import CreateProjectForm from "./new/components/project-register2.component";
import MyProject from "./new/components/project-profile.component";
import CompanyList from "./new/components/join-company.component";
import ProjectList from "./new/components/join-project.component";
import HomePage from "./pages/Home/HomePage";
import Office from "./pages/Table/Office";
import OfficeEdit from "./pages/Table/OfficeEdit";
import AddNewElement from "./pages/AddNewElement";
import OnSitePage from "./OnSite/OnSitePage";
import ErrorPage from "./pages/Error/ErrorPage";
import NavigationBar from "./new/components/NavigationBar";

import * as React from 'react';
import {Route, Routes} from "react-router-dom";
import CompanyTablePage from "./pages/CompanyTables/CompanyTablePage";
import CreateBaseProduct from "./pages/CreateBaseProduct";

function App2() {
    return (
        <div>
            <NavigationBar/>
            <div
                className="container"
                style={{marginTop: '80px', paddingLeft: '10px', paddingRight: '10px', paddingBottom: '10px', maxWidth: '100%'
            }}
            >
                <Routes>
                    <Route path="/" element={<HomePage/>}/>
                    <Route path="/home" element={<HomePage/>}/>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/signup" element={<Signup/>}/>
                    <Route path="/profile" element={<Profile/>}/>
                    <Route path="/dashboard" element={<DashBoard/>}/>
                    <Route path="/projectadmin" element={<ProjectAdminBoard/>}/>
                    <Route path="/companyadmin" element={<CompanyAdminBoard/>}/>
                    <Route path="/admin" element={<BoardAdmin/>}/>
                    <Route path="/session" element={<Session/>}/>
                    <Route path="/register-company" element={<CompanyRegister/>}/>
                    <Route path="/register-project2" element={<CreateProjectForm/>}/>
                    <Route path="/my-company" element={<MyCompany/>}/>
                    <Route path="/my-project" element={<MyProject/>}/>
                    <Route path="/join-company" element={<CompanyList/>}/>
                    <Route path="/join-project" element={<ProjectList/>}/>
                    <Route path="/office" element={<Office/>}/>
                    <Route path="/office-edit" element={<OfficeEdit/>}/>
                    <Route path="/new" element={<AddNewElement/>}/>
                    <Route path="/new-base-product" element={<CreateBaseProduct/>}/>
                    <Route path="/onsite" element={<OnSitePage/>}/>
                    <Route path="/error" element={<ErrorPage/>}/>
                    <Route path="/gc" element={<CompanyTablePage/>}/>
                </Routes>
            </div>
        </div>
    );
}

export default App2;



