import React, {Component} from "react";

import EventBus from "../common/EventBus";
import {withRouter} from '../common/with-router';
import UserService from "../services/user.service";
import LocalStorageService from "../services/localStorageService";
import ProjectService from "../services/project.service";
import {Link} from "react-router-dom";

class Dashboard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            companyMonogram: null,
            userReady: false,
            projectName: null,
        };
    }

    async componentDidMount() {
        const user = LocalStorageService.getUser();
        try {
            const company = await UserService.getCompanyByCurrentUserId(user.id)
            if (company) {
                this.setState((prevState) => ({
                    ...prevState,
                    companyMonogram: company.companyNameMonogram
                }));
                if (company.projects.length > 0) {
                    const project = await ProjectService.getProjectByMonogram(company.projects[0].projectMonogram);
                    if (project) {
                        this.setState((prevState) => ({
                            ...prevState,
                            projectName: project.projectMonogram
                        }));
                    }
                }
            }
            this.setState((prevState) => ({
                ...prevState,
                userReady: true
            }));
        } catch (error) {
            console.error('Error fetching companies:', error);
            this.setState({
                companyMonogram:
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString(),
                userReady: false

            });
            if (error.response) {
                if (error.response.status === 403 || error.response.status === 400) {
                    this.props.router.navigate("/session")
                    EventBus.dispatch("logout");
                }
                EventBus.dispatch("logout");
            }
        }

    }

    render() {
        return (
            <div className="container">
                {(this.state.userReady) ?
                    <div>
                        <div className="container">
                            {this.state.companyMonogram ? this.state.projectName ? null : (
                                <>
                                    <header className="jumbotron" style={{textAlign: 'center', padding: '10px'}}>
                                        <h5>
                                            <div>Awesome work! You're now a member of
                                                the <strong>{this.state.companyMonogram}</strong> crew!
                                            </div>
                                            <div> Ready to jump into a
                                                project or start one yourself?
                                            </div>
                                        </h5>
                                    </header>
                                </>
                            ) : (
                                <div className="container mt-5">
                                    <div className="row">
                                        <div className="col-md-6 offset-md-3">
                                            <div className="text-center">
                                                <h2>Company Actions</h2>
                                            </div>
                                            <div className="d-flex flex-column">
                                                <Link to={"/register-company"} className="btn btn-primary mb-3">Register
                                                    New Company</Link>
                                                <Link to={"/join-company"} className="btn btn-info mb-3">Join an Existing
                                                    Company</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>)}

                            {!this.state.projectName ? this.state.companyMonogram ? (
                                <>
                                    <div className="container mt-5">
                                        <div className="row">
                                            <div className="col-md-6 offset-md-3">
                                                <div className="text-center">
                                                    <h2>Project Actions</h2>
                                                </div>
                                                <div className="d-flex flex-column">
                                                    <Link to={"/register-project2"} className="btn btn-success mb-3">Create
                                                        New
                                                        Project</Link>
                                                    <Link to={"/join-project"} className="btn btn-warning mb-3">Join an
                                                        Existing
                                                        Project</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : null : (
                                <>
                                    <header className="jumbotron" style={{textAlign: 'center', padding: '10px'}}>
                                        <h5>
                                            Great job! You're part of
                                            the <strong>{this.state.companyMonogram}</strong> company and
                                            the <strong>{this.state.projectName}</strong> project!
                                            🎉👏
                                        </h5>
                                    </header>
                                </>
                            )}
                        </div>
                    </div> : null}
            </div>
        );
    }
}

export default withRouter(Dashboard);
