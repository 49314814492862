import React, {Component} from "react";
import {Navigate} from "react-router-dom";
import UserService from "../services/user.service";
import LocalStorageService from "../services/localStorageService";
import CompanyService from "../services/company.service";

export default class MyCompany extends Component {
    constructor(props) {
        super(props);

        this.state = {
            redirect: null,
            userReady: false,
            currentUser: {username: ""},
            companyName: {companyName: ""},
            company: {company: ""},
            companyMembers: []
        };
    }

    async componentDidMount() {
        const currentUser = LocalStorageService.getUser();
        try {
            const response1 = await UserService.getCompanyByCurrentUserId(currentUser.id);
            const response2 = await CompanyService.getUsersByCompanyId(response1.id);
            if (!currentUser) this.setState({redirect: "/home"});
            this.setState({
                currentUser: currentUser,
                userReady: true,
                companyName: response1.companyNameMonogram,
                company: response1,
                companyMembers: response2
            })
        } catch (error) {
            console.error('Error fetching companies:', error);
        }
    }

    render() {
        if (this.state.redirect) {
            return <Navigate to={this.state.redirect}/>
        }

        const {companyName, company, companyMembers} = this.state;

        return (
            <div className="container" style={{paddingBottom: '30px'}}>
                {(this.state.userReady) ?
                    <div>
                        <header className="jumbotron">
                            <h3>
                                <strong>{companyName}</strong> Company Profile
                            </h3>
                        </header>
                        <p>
                            <strong>Id:</strong>{" "}
                            {company.id}
                        </p>
                        <p>
                            <strong>Name:</strong>{" "}
                            {company.shortName} {company.formOfEnterprise}
                        </p>
                        <strong>Address:</strong>
                        <ul>
                            <li>
                                <strong>Street:</strong>{" "}
                                {company.street}
                            </li>
                            <li>
                                <strong>Building:</strong>{" "}
                                {company.buildingId}
                            </li>
                            <li>
                                <strong>Apartment:</strong>{" "}
                                {company.apartmentId}
                            </li>
                            <li>
                                <strong>City:</strong>{" "}
                                {company.city}
                            </li>
                            <li>
                                <strong>Postal Code:</strong>{" "}
                                {company.postalCode}
                            </li>
                            <li>
                                <strong>Country:</strong>{" "}
                                {company.country}
                            </li>
                        </ul>
                        <strong>Projects:</strong>
                        <ul>
                            {company.projects && company.projects.length > 0 ? (
                                company.projects.map((project, index) => (
                                    <li key={index}>
                                        <b>{project.projectMonogram}</b> - {project.projectType} Project
                                        | <b>{company.companyNameMonogram}</b> as {project.companyProjectRoleType}
                                    </li>
                                ))
                            ) : (
                                <li> This company is currently not participating in any projects. </li>
                            )}
                        </ul>
                        <div>
                            <strong>Members:</strong>{" "}
                            <ol>
                                {companyMembers && companyMembers.length > 0 ? (
                                    companyMembers.map((co_member, index) => (
                                        <li key={index}>
                                             Member:
                                            <ul>
                                                <li>
                                                    <b>Name: </b> {co_member.username}
                                                </li>
                                                <li>
                                                    <b>Title: </b> {co_member.memberTitleName}
                                                </li>
                                                <li>
                                                    <b>Authorities: </b> {co_member.userRoleAtCompany}
                                                </li>
                                            </ul>
                                        </li>
                                    ))
                                ) : (
                                    <li> To this company haven't assigned any member yet! </li>
                                )}
                            </ol>
                        </div>
                    </div> : null}
            </div>
        );
    }
}
