import React, {Component} from "react";
import {Navigate} from "react-router-dom";
import LocalStorageService from "../services/localStorageService";
import UserService from "../services/user.service";

export default class Profile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            redirect: null,
            userReady: false,
            currentUser: {username: ""},
            companies: '',
            projects: ''
        };
    }

    async componentDidMount() {
        try {
            const currentUser = LocalStorageService.getUser();
            const response = await UserService.getUserDetailsById(currentUser.id);
            this.setState({
                companies: response.userCompanyMappings,
                projects: response.userProjectMappings,
                userReady: true
            });
            if (!currentUser) this.setState({redirect: "/home"});
            this.setState({currentUser: currentUser, userReady: true})
        } catch (error) {
            console.error('Error fetching companies:', error);
        }
    }

    render() {
        if (this.state.redirect) {
            return <Navigate to={this.state.redirect}/>
        }

        const {currentUser, companies, projects} = this.state;

        return (
            <div className="container">
                {(this.state.userReady) ?
                    <div>
                        <header className="jumbotron">
                            <h3>
                                <strong>{currentUser.username}</strong> Profile
                            </h3>
                        </header>
                        <p>
                            <strong>Id:</strong>{" "}
                            {currentUser.id}
                        </p>
                        <p>
                            <strong>Email:</strong>{" "}
                            {currentUser.email}
                        </p>
                        <p>
                            <strong>Application Authorities:</strong>
                        </p>
                            <ul>
                                {currentUser.roles &&
                                    currentUser.roles.map((role, index) => <li key={index}>{role}</li>)}
                            </ul>

                        <p>
                            <strong>Companies:</strong>{" "}
                        </p>
                        <ul>
                            {companies && companies.length > 0 ? (
                                companies.map((company, index) => (
                                    <li key={index}>
                                        Member of <b>{company.company2.companyNameMonogram} </b> Company
                                        as {company.currentPositionTitleName} | {company.userRoleAtCompany}
                                    </li>
                                ))
                            ) : (
                                <li> You haven't been assigned to any companies yet! </li>
                            )}
                        </ul>
                        <p>
                            <strong>Projects:</strong>{" "}
                        </p>
                        <ul>
                            {projects && projects.length > 0 ? (
                                projects.map((project, index) => (
                                    <li key={index}>
                                        At <b>{project.project.projectMonogram} </b> Project |
                                        in <b>{project.project.companies[0].companyMonogram} </b> Team [with {project.userProjectRoleType}]
                                    </li>
                                ))
                            ) : (
                                <li> You haven't been assigned to any projects yet! </li>
                            )}
                        </ul>
                    </div> : null}
            </div>
        );
    }
}
