import axios from "axios";
import LocalStorageService from "./localStorageService";
import UserService from "./user.service";

const API_URL = `${process.env.REACT_APP_BASEURL}/api/v1/auth/`;
const login = async (username, password) => {
    try {
        const response = await axios.post(API_URL + "login", {
            username,
            password,
        });

        if (response.data.username) {
            LocalStorageService.setUser(response.data);
        }

        await UserService.getCompanyByCurrentUserId(response.data.id);

        return response.data;
    } catch (error) {
        // Handle errors here
        console.error("Login error:", error);
        throw error;
    }
}
const logout = () => {
    LocalStorageService.removeUser();
    LocalStorageService.removeUserDetails();
    LocalStorageService.removeCompanyMonogram();
    LocalStorageService.removeCurrentProject();
    LocalStorageService.removeCompanyProjectRoleType();
    return axios.post(API_URL + "signout").then((response) => {
        return response.data;
    });
}

const register = (username, email, password) => {
    return axios.post(API_URL + "signup", {
        username,
        email,
        password,
    });
}


const AuthService = {
    register,
    login,
    logout
}

export default AuthService;
