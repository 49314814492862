import React from 'react';

function ErrorPage() {
    return (
        <div style={{padding: '20px'}}>
            <h1>Oops! Something went wrong.</h1>
            <p>Please try again later or contact support.</p>
        </div>
    );
}

export default ErrorPage;
