import React, { Component } from "react";
import { Modal, Button, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './RoleModal.css';
import { CompanyProjectRoleType } from "../common/enums";

function CustomModalHeader({ onClose, subject, name, nameOrigin }) {
    return (
        <div className="modal-header">
            <h5 className="modal-title">
                {subject} Role at
                <b style={{ color: "#0069d9" }}> {name} </b>
                {nameOrigin} </h5>
            <button type="button" className="close" aria-label="Close" onClick={onClose}>
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    );
}

export default class RoleModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            role: '',
            disabled: true,
        };
    }

    handleRoleChange = (e) => {
        const role = e.target.value;
        this.setState({ role, disabled: role.trim() === '' });
    };

    handleKeyDown = (e) => {
        if (e.key === 'Enter' && !this.state.disabled) {
            e.preventDefault(); // Prevent the default Enter key behavior (form submission)
            this.handleJoin();
        }
    };

    handleJoin = () => {
        const { handleJoin } = this.props;
        handleJoin(this.state.role);
    };

    render() {
        const { show, handleClose, subject, name, nameOrigin } = this.props;
        return (
            <Modal show={show} onHide={handleClose} className="centered-modal">
                <CustomModalHeader subject={subject} onClose={handleClose} name={name} nameOrigin={nameOrigin} />
                <Form className="was-validated" onKeyDown={this.handleKeyDown}>
                    <Modal.Body>
                        {nameOrigin === "Company" ? (<Form.Group>
                            <Form.Control
                                type="text"
                                placeholder="Enter the role"
                                value={this.state.role}
                                onChange={this.handleRoleChange}
                                required
                            />
                            <div className="invalid-feedback">
                                Role is required.
                            </div>
                        </Form.Group>) : (
                            <>
                                <Form.Control
                                    as="select"
                                    name="type"
                                    value={this.state.role}
                                    onChange={this.handleRoleChange}
                                    required
                                >
                                    <option value="">Select project type</option>
                                    {Object.entries(CompanyProjectRoleType).map(([key, value]) => (
                                        <option key={key} value={key}>
                                            {value}
                                        </option>
                                    ))}
                                </Form.Control>
                                <div className="invalid-feedback">
                                    Role is required.
                                </div>
                            </>)}

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={this.handleJoin} disabled={this.state.disabled}>
                            Join
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        );
    }
}
