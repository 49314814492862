import * as React from 'react';
import {createSvgIcon} from '@mui/material/utils';
import {
    DataGrid,
    gridClasses,
    GridToolbarContainer,
    GridToolbarFilterButton, GridToolbarQuickFilter, GridToolbarColumnsButton,
    gridPaginatedVisibleSortedGridRowIdsSelector,
    gridExpandedSortedRowIdsSelector,
    useGridApiContext,
} from '@mui/x-data-grid';
import {alpha, styled} from '@mui/material/styles';
import {Box, Button} from "@mui/material";
import {useState} from "react";

function addPrefixAndConvert(prefix, input) {
    // Remove the specified prefix and format the text
    const formattedText = input.replace(new RegExp(`^${prefix}`, 'i'), '');

    // Split the formatted text by camelCase and join with spaces
    return formattedText.replace(/([a-z])([A-Z])/g, '$1 $2');
}

function removeFirstTwoCharactersAndConvert(input) {
    // Remove the first two characters and format the remaining text
    const formattedText = input.slice(2);

    // Split the formatted text by camelCase and join with spaces
    return formattedText.replace(/([a-z])([A-Z])/g, '$1 $2');
}
const ODD_OPACITY = 0.1;

const StripedDataGrid = styled(DataGrid)(({theme}) => ({
    [`& .${gridClasses.row}.even`]: {
        backgroundColor: theme.palette.grey[200],
        '&:hover, &.Mui-hovered': {
            backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
        '&.Mui-selected': {
            backgroundColor: alpha(
                theme.palette.primary.main,
                ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
            '&:hover, &.Mui-hovered': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    ODD_OPACITY +
                    theme.palette.action.selectedOpacity +
                    theme.palette.action.hoverOpacity,
                ),
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        ODD_OPACITY + theme.palette.action.selectedOpacity,
                    ),
                },
            },
        },
    },
}));

const ExportIcon = createSvgIcon(
    <path
        d="M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z"/>,
    'SaveAlt',
);

function CustomToolbar() {
    const apiRef = useGridApiContext();

    const buttonBaseProps = {
        color: 'primary',
        size: 'small',
        startIcon: <ExportIcon/>,
    };
    const getRowsFromCurrentPage = ({apiRef}) =>
        gridPaginatedVisibleSortedGridRowIdsSelector(apiRef);
    const getFilteredRows = ({apiRef}) => gridExpandedSortedRowIdsSelector(apiRef);
    const handleExport = (options) => apiRef.current.exportDataAsCsv(options);

    return (
        <GridToolbarContainer>
            <GridToolbarQuickFilter printoptions={{disableToolbarButton: true}}/>
            <GridToolbarColumnsButton printoptions={{disableToolbarButton: true}}/>
            <GridToolbarFilterButton printoptions={{ disableToolbarButton: true }} />
            <Button
                {...buttonBaseProps}
                onClick={() => handleExport({getRowsToExport: getFilteredRows})}
            >
                Filtered rows
            </Button>
        </GridToolbarContainer>
    );
}

const DetailedDataGrid = ({products, condition}) => {

    const [tableData] = useState(products);

    // Map the data to rows dynamically
    const rows = tableData.map((item) => {
        const row = { id: item.id };

        // Include properties with 'general'
        Object.keys(item).forEach((key) => {
            if (key.includes(condition)) {
                row[key] = item[key];
            }
        });

        // Include remaining properties
        Object.keys(item).forEach((key) => {
            if (!key.includes(condition)) {
                row[key] = item[key];
            }
        });

        return row;
    });

    // Define the columns dynamically for properties with 'id'
    const idColumn = Object.keys(tableData[0])
        .filter((key) => key.includes('id'))
        .map((key) => ({
            field: key,
            headerName: key, // You can customize the header as needed
            width: 50, // Set the width as required
        }));

    // Define the columns dynamically with conditions
    const ownColumns = Object.keys(tableData[0])
        .filter((key) => key.includes(condition))
        .map((key) => ({
            field: key,
            type: key === "pmQty" || key === "slAgreedPrice" ? 'number' : '',
            headerName: addPrefixAndConvert(condition, key), // You can customize the header as needed
            width: key.length + 100, // Set the width as required
        }));

    // Define the columns for times
    const timesColumns = Object.keys(tableData[0])
        .filter((key) => key.includes('time'))
        .map((key) => ({
            field: key,
            headerName: addPrefixAndConvert('time', key), // You can customize the header as needed
            width: 155, // Set the width as required
        }));


    // Define the columns for the rest of the properties
    const remainingColumns = Object.keys(tableData[0])
        .filter((key) => !key.includes('id') && !key.includes(condition) && !key.includes('time'))
        .map((key) => ({
            field: key,
            type: key === "pmQty" || key === "slAgreedPrice" ? 'number' : '',
            headerName: removeFirstTwoCharactersAndConvert(key), // You can customize the header as needed
            width: key.length + 100, // Set the width as required
        }));


    // Combine
    const columns = [...idColumn, ...ownColumns,...remainingColumns, ...timesColumns];


    const StyledGridOverlay = styled('div')(({theme}) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        '& .ant-empty-img-1': {
            fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
        },
        '& .ant-empty-img-2': {
            fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
        },
        '& .ant-empty-img-3': {
            fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
        },
        '& .ant-empty-img-4': {
            fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
        },
        '& .ant-empty-img-5': {
            fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
            fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
        },
    }));

    function CustomNoRowsOverlay() {
        return (
            <StyledGridOverlay>
                <svg
                    width="120"
                    height="100"
                    viewBox="0 0 184 152"
                    aria-hidden
                    focusable="false"
                >
                    <g fill="none" fillRule="evenodd">
                        <g transform="translate(24 31.67)">
                            <ellipse
                                className="ant-empty-img-5"
                                cx="67.797"
                                cy="106.89"
                                rx="67.797"
                                ry="12.668"
                            />
                            <path
                                className="ant-empty-img-1"
                                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                            />
                            <path
                                className="ant-empty-img-2"
                                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                            />
                            <path
                                className="ant-empty-img-3"
                                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                            />
                        </g>
                        <path
                            className="ant-empty-img-3"
                            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                        />
                        <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
                            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815"/>
                            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z"/>
                        </g>
                    </g>
                </svg>
                <Box sx={{mt: 1}}>No Rows</Box>
            </StyledGridOverlay>
        )
            ;
    }

    return (
        <>
            <div style={{
                height: '75vh'
                , width: '100%'
                ,
                backgroundColor: '#fff', borderRadius: '6px'
                , minWidth: '250px'
            }}>
                <StripedDataGrid
                    slots={{
                        noRowsOverlay: CustomNoRowsOverlay,
                        toolbar: CustomToolbar,
                    }}
                    initialState={{
                        pagination: {
                            paginationModel: {page: 0, pageSize: 20},
                        },
                    }}
                    density="compact"
                    pageSizeOptions={[20, 25, 50]}
                    rows={rows}
                    columns={columns}
                    sx={{
                        boxShadow: 1,
                        border: 1,
                        borderColor: 'primary.light',
                        '& .MuiDataGrid-cell:hover': {
                            color: 'primary.main',
                        },
                    }}
                    getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                    }
                />
            </div>
        </>
    );
}
export default DetailedDataGrid;
