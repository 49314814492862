import axios from "axios";
import jwtInterceptor from "./jwtInterceptor";
import LocalStorageService from "./localStorageService";
import ProjectService from "./project.service";

const API_URL = `${process.env.REACT_APP_BASEURL}/api/v1/companies`;

axios.defaults.withCredentials = true

const registerNewCompanyAndAddFirstUser = (company, userDetails, user) => {
    const dto = {
        shortName: company.shortName,
        formOfEnterprise: company.formOfEnterprise,
        companyNameMonogram: company.companyNameMonogram,
        street: company.street,
        buildingId: company.buildingId,
        apartmentId: company.apartmentId,
        city: company.city,
        postalCode: company.postalCode,
        country: company.country,

        username: user.username,
        userRoleAtCompany: userDetails.userRoleAtCompany,
        currentPositionTitleName: userDetails.currentPositionTitleName
    }
    console.log(dto)
    return axios.post(API_URL, dto).then((response) => {
        return response.data;
    })
}

const handleUserJoinRequestToCompany = (companyId, username, currentPositionTitleName) => {
    const dto = {
        companyId: companyId,
        username: username,
        currentPositionTitleName: currentPositionTitleName
    }
    console.log(dto)
    return axios.post(`${API_URL}/join-user`, dto).then((response) => {
        LocalStorageService.setCompanyMonogram(response.data);
        if (response.data.projects.length > 0) {
            ProjectService.getProjectByMonogram(response.data.projects[0].projectMonogram);
        }
        return response.data;
    })
}

const getAllCompanies = () => {
    return jwtInterceptor.get(API_URL).then((response) => {
        return response.data;
    }).catch(error => {
        // Handle any errors that occurred during the request
        console.error('Error:', error);
    });
}

const getCompanyByMonogram = (monogram) => {
    console.log(monogram.companyName)
    return jwtInterceptor.get(`${API_URL}/monogram/${monogram}`).then((response) => {
        return response.data;
    }).catch(error => {
        // Handle any errors that occurred during the request
        console.error('Error:', error);
    });
}

const getUsersByCompanyId = (companyId) => {
    return axios.get(`${API_URL}/${companyId}/users`).then((response) => {
        return response.data;
    }).catch(error => {
        // Handle any errors that occurred during the request
        console.error('Error:', error);
    });
};

const getNumbersOfCompanyMembers = (companyId) => {
    return axios.get(`${API_URL}/${companyId}/member-numbers`).then((response) => {
        return response.data;
    }).catch(error => {
        // Handle any errors that occurred during the request
        console.error('Error:', error);
    });
};


// const getUserBoard = () => {
//     return jwtInterceptor.get(API_URL + 'user');
// }

// const getProjectAdminBoard = () => {
//     return jwtInterceptor.get(API_URL + 'projectadmin');
// }


// const getCompanyAdminBoard = () => {
//     return jwtInterceptor.get(API_URL + 'companyadmin');
// }

// const getAdminBoard = () => {
//     return jwtInterceptor.get(API_URL + 'admin');
// }

const CompanyService = {
    registerNewCompanyAndAddFirstUser,
    getAllCompanies,
    getCompanyByMonogram,
    handleUserJoinRequestToCompany,
    getUsersByCompanyId,
    getNumbersOfCompanyMembers
}

export default CompanyService;
