import React, {Component} from 'react';
import ItemCard from './ItemCard';

import RoleModal from "./RoleModal";
import LocalStorageService from "../services/localStorageService";
import ProjectService from "../services/project.service";
import {withRouter} from "../common/with-router";
import {Button} from "react-bootstrap";

class ProjectList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            projects: [],
            userReady: false,
            joinStatus: {},
            loading: false,
            companyHasProject: false,
            selectedProjectId: null,
            selectedProjectName: null,
            selectedProjectType: null,
            companyRole: '',
            username: '',
            companyMonogram: ''
        };
    }

    handleOpenModal = (projectId, projectName, projectType) => {
        this.setState({
            selectedProjectId: projectId,
            selectedProjectName: projectName,
            selectedProjectType: projectType
        });
    };

    handleCloseModal = () => {
        this.setState({selectedProjectId: null, selectedProjectName: null, selectedProjectType: null});
    };

    async componentDidMount() {
        const user = LocalStorageService.getUser().username;
        const companyMonogram = LocalStorageService.getCompanyMonogram();
        this.setState((prevState) => ({
            ...prevState,
            username: user,
            companyMonogram: companyMonogram.companyNameMonogram
        }));
        try {
            const response = await ProjectService.getAllCompanies();
            this.setState({projects: response, userReady: true});
        } catch (error) {
            console.error('Error fetching projects:', error);
        }
    }

    handleJoin = (projectId, companyRole) => {
        if (this.state.joinStatus[projectId] === 'joining') {
            return;
        }

        this.setState({loading: true});

        this.setState((prevState) => ({
            joinStatus: {
                ...prevState.joinStatus,
                [projectId]: 'joining',
            },
        }));

        try {
            ProjectService.handleCompanyJoinRequestToProject(projectId, this.state.companyMonogram, this.state.username, companyRole).then(
                () => {
                    this.setState((prevState) => ({
                        joinStatus: {
                            ...prevState.joinStatus,
                            [projectId]: 'joined',
                        },
                        userHasCompany: true,
                        loading: false,
                    }));
                }
            ).then(() => {
                    this.props.router.navigate("/dashboard");
                    window.location.reload();
                }
            )

        } catch (e) {
            console.log("Error: " + e)
        }
    };

    handleBack = () => {
        this.props.router.navigate("/dashboard")
    };

    render() {
        const {projects} = this.state;
        return (
            <div>
                {(this.state.userReady) ?
                    <div>
                        <h2>Projects</h2>
                        <div
                            // className="card-deck"
                        >
                            {projects && projects.length > 0 ? (projects.map((project) => (
                                <ItemCard
                                    key={project.id}
                                    keyId={project.id}
                                    itemName={project.projectName}
                                    itemFrom={project.projectType}
                                    monogram={project.projectMonogram}
                                    city={project.city}
                                    joinStatus={this.state.joinStatus[project.id]}
                                    loading={this.state.loading}
                                    has={this.state.companyHasProject}
                                    openModal={this.handleOpenModal}
                                    optionalInfo={''}
                                />
                            ))) : (<>
                                <div className="jumbotron" style={{textAlign: 'center', padding: '10px'}}>
                                    <h5>
                                        <div>The company list is empty!</div>
                                    </h5>
                                </div>
                                <div style={{textAlign: 'center', paddingBottom: "40px"}}>
                                    <Button
                                        variant="secondary"
                                        onClick={this.handleBack}
                                        style={{
                                            width: '100px',
                                            padding: '10px 0',
                                            margin: '10px',
                                        }}
                                    >
                                        Back
                                    </Button>
                                </div>
                            </>)}
                        </div>
                        <div>
                            <Button
                                variant="secondary"
                                onClick={this.handleBack}
                                style={{
                                    width: '100px',
                                    padding: '10px 0',
                                    margin: '10px',
                                }}
                            >
                                Back
                            </Button>
                        </div>
                        <RoleModal
                            show={this.state.selectedProjectId !== null}
                            handleClose={this.handleCloseModal}
                            handleJoin={(role) => {
                                this.handleJoin(this.state.selectedProjectId, role);
                                this.handleCloseModal();
                            }}
                            subject={"Company"}
                            name={this.state.selectedProjectName}
                            nameForm={this.state.selectedProjectType}
                            nameOrigin={"Project"}
                        />
                    </div> : null}


            </div>
        );
    }
}

export default withRouter(ProjectList);
