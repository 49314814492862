import React from 'react';
import {Card} from "@mui/material"
import BaseProductForm from "../components/form/BaseProductForm";

const CreateBaseProduct = () => {

    return (
        <>
            <h1 style={{textAlign: "center", marginBottom: '15px'}}>New Product Form</h1>
            <Card sx={{
                p: 3,
                py: 5,
                maxWidth: "550px",
                margin: "auto",
                display: "flex",
                flexDirection: "column",
                gap: 0,
                borderRadius: "15px"
            }} elevation={10}>
                <BaseProductForm/>
            </Card>
        </>
    )
}

export default CreateBaseProduct;
