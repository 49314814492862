import React, {Component} from 'react';
import CompanyService from '../services/company.service';
import ItemCard from './ItemCard';

import RoleModal from "./RoleModal";
import LocalStorageService from "../services/localStorageService";
import {withRouter} from "../common/with-router";
import {Button} from "react-bootstrap";

class CompanyList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            companies: [],
            companyMembersNumbers: [],
            userReady: false,
            joinStatus: {},
            loading: false,
            userHasCompany: false,
            selectedCompanyId: null,
            selectedCompanyName: null,
            selectedEnterpriseForm: null,
            userRole: '',
            username: ''
        };
    }

    handleOpenModal = (companyId, companyName, enterpriseForm) => {
        this.setState({
            selectedCompanyId: companyId,
            selectedCompanyName: companyName,
            selectedEnterpriseForm: enterpriseForm
        });
    };

    handleCloseModal = () => {
        this.setState({selectedCompanyId: null, selectedCompanyName: null, selectedEnterpriseForm: null});
    };

    async componentDidMount() {
        const user = LocalStorageService.getUser().username;
        this.setState((prevState) => ({
            ...prevState,
            username: user
        }));
        try {
            const response = await CompanyService.getAllCompanies();
            let dict = {};
            for (let responseElement of response) {
                dict[responseElement.id] = await CompanyService.getNumbersOfCompanyMembers(responseElement.id);
            }

            this.setState({companies: response, companyMembersNumbers: dict, userReady: true});

        } catch (error) {
            console.error('Error fetching companies:', error);
        }
    }

    handleBack = () => {
        this.props.router.navigate("/dashboard")
    };

    handleJoin = (companyId, role) => {
        if (this.state.joinStatus[companyId] === 'joining') {
            return;
        }

        this.setState({loading: true});

        this.setState((prevState) => ({
            joinStatus: {
                ...prevState.joinStatus,
                [companyId]: 'joining',
            },
        }));

        try {
            CompanyService.handleUserJoinRequestToCompany(companyId, this.state.username, role).then(
                (response) => {
                    console.log(response)
                    this.setState((prevState) => ({
                        joinStatus: {
                            ...prevState.joinStatus,
                            [companyId]: 'joined',
                        },
                        userHasCompany: true,
                        loading: false,
                    }));
                    this.props.router.navigate("/dashboard");
                    // window.location.reload();
                }
            )
        } catch (e) {
            console.log("Error: " + e)
        }
    };

    render() {
        const {companies, companyMembersNumbers} = this.state;
        return (
            <div>
                {(this.state.userReady) ?
                    <div>
                        <h2>Companies</h2>
                        <div
                            // className="card-deck"
                        >
                            {companies && companies.length > 0 ? (companies.map((company) => (
                                <ItemCard
                                    key={company.id}
                                    keyId={company.id}
                                    itemName={company.shortName}
                                    itemFrom={company.formOfEnterprise}
                                    monogram={company.companyMonogram}
                                    city={company.city}
                                    joinStatus={this.state.joinStatus[company.id]}
                                    loading={this.state.loading}
                                    has={this.state.userHasCompany}
                                    openModal={this.handleOpenModal}
                                    optionalInfo={companyMembersNumbers[company.id]}
                                />
                            ))) : (<>
                                <div className="jumbotron" style={{textAlign: 'center', padding: '10px'}}>
                                    <h5>
                                        <div>The company list is empty!</div>
                                    </h5>
                                </div>
                                <div style={{textAlign: 'center'}}>
                                    <Button
                                        variant="secondary"
                                        onClick={this.handleBack}
                                        style={{
                                            width: '100px',
                                            padding: '10px 0',
                                            margin: '10px',
                                        }}
                                    >
                                        Back
                                    </Button>
                                </div>
                            </>)}
                        </div>
                        <div style={{paddingBottom: "40px"}}>
                            <Button
                                variant="secondary"
                                onClick={this.handleBack}
                                style={{
                                    width: '100px',
                                    padding: '10px 0',
                                    margin: '10px',
                                }}
                            >
                                Back
                            </Button>
                        </div>
                        <RoleModal
                            show={this.state.selectedCompanyId !== null}
                            handleClose={this.handleCloseModal}
                            handleJoin={(role) => {
                                this.handleJoin(this.state.selectedCompanyId, role);
                                this.handleCloseModal();
                            }}
                            subject={"Your"}
                            name={this.state.selectedCompanyName}
                            nameOrigin={"Company"}
                        />
                    </div> : null}

            </div>
        );
    }
}

export default withRouter(CompanyList);
