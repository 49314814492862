import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormLabel from '@mui/material/FormLabel';
import Button from "@mui/material/Button";
import React, {useState} from "react";
import SaveIcon from "@mui/icons-material/Save";
import {useNavigate} from "react-router-dom";
import LoadingButton from '@mui/lab/LoadingButton';
import {Alert, Snackbar} from "@mui/material";
import {QuantityTypes, productStatusTypes} from "../../new/common/enums";
import {Form} from "react-bootstrap";

const createBaseProduct = (product) => {
    return fetch(`${process.env.REACT_APP_BASEURL}/api/v1/CONSTRUCTION_PROJECT_MANAGEMENT/saveProductBaseAndSite`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(product),
    }).then((res) => res.json()).catch((err) => {
        console.log(err);
    });
};

const BaseProductForm = () => {
    const navigate = useNavigate();
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const [productName, setProductName] = useState("");
    const [categoryType, setCategoryType] = useState("");
    const [quantity, setQuantity] = useState("");
    const [quantityType, setQuantityType] = useState("db");
    const [productStatusType, setProductStatusType] = useState("Recorded");
    const [buildingId, setBuildingId] = useState("");
    const [unitId, setUnitId] = useState("");
    const [roomId, setRoomId] = useState("");

    const [snackbar, setSnackbar] = React.useState(null);
    const handleCloseSnackbar = () => setSnackbar(null);


    const handleCreateProduct = (product) => {
        createBaseProduct(product)
            .then(() => {
                setButtonDisabled(false);
                setSnackbar({children: 'New product parameters are saved', severity: 'success'});

            })
            .catch((err) => {
                setSnackbar({children: 'Something went wrong!', severity: 'error'});
                setButtonDisabled(true);
                throw err;
            });
    };

    const onSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        const entries = [...formData.entries()];
        setButtonDisabled(true);

        const newProduct = entries.reduce((acc, entry) => {
            const [k, v] = entry;
            acc[k] = v;
            return acc;
        }, {});

        setCategoryType("");
        setProductName("");
        setQuantity("");
        setQuantityType("");
        setBuildingId("");
        setUnitId("");
        setRoomId("");

        return handleCreateProduct(newProduct);
    };

    return (
        <Box
            sx={{
                display: "flex",
                gap: "1rem",
                flexWrap: "wrap",
            }}
            component="form"
            onSubmit={onSubmit}
        >
            <FormControl fullWidth>
                <TextField
                    name="pmCategoryName"
                    id="pmCategoryName"
                    label="Category Name"
                    variant="outlined"
                    value={categoryType}
                    onChange={(e) => setCategoryType(e.target.value)}
                    required
                />
            </FormControl>

            <FormControl fullWidth>
                <TextField
                    name="pmProductName"
                    id="pmProductName"
                    label="Product Name"
                    variant="outlined"
                    value={productName}
                    onChange={(e) => setProductName(e.target.value)}
                    required
                />
            </FormControl>

            <FormControl fullWidth>
                <TextField
                    name="pmQty"
                    id="pmQty"
                    label="Quantity"
                    type="number"
                    variant="outlined"
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                    required
                />
            </FormControl>

            <Form.Control
                as="select"
                name="pmQtyType"
                id="pmQtyType"
                label="Quantity Type"
                variant="outlined"
                value={quantityType}
                onChange={(e) => setQuantityType(e.target.value)}
                required
            >
                <option value="">Select Quantity Type</option>
                {Object.entries(QuantityTypes).map(([key]) => (
                    <option key={key} value={key}>
                        {key}
                    </option>
                ))}
            </Form.Control>

            <Form.Control
                as="select"
                name="pmProductStatus"
                id="pmProductStatus"
                label="Product Status Type"
                variant="outlined"
                value={productStatusType}
                onChange={(e) => setProductStatusType(e.target.value)}
                required
            >
                <option value="">Select Product Status</option>
                {Object.entries(productStatusTypes).map(([key]) => (
                    <option key={key} value={key}>
                        {key}
                    </option>
                ))}
            </Form.Control>

            <FormControl fullWidth>
                <FormLabel>Location Details:</FormLabel>
                <TextField
                    name="pmBuildingId"
                    id="pmBuildingId"
                    label="Building Id"
                    variant="outlined"
                    value={buildingId}
                    onChange={(e) => setBuildingId(e.target.value)}
                    required
                />
            </FormControl>

            <FormControl fullWidth>
                <TextField
                    name="pmUnitId"
                    id="pmUnitId"
                    label="Unit Id"
                    variant="outlined"
                    value={unitId}
                    onChange={(e) => setUnitId(e.target.value)}
                    required
                />
            </FormControl>

            <FormControl fullWidth>
                <TextField
                    name="pmRoomId"
                    id="pmRoomId"
                    label="Room Id"
                    variant="outlined"
                    value={roomId}
                    onChange={(e) => setRoomId(e.target.value)}
                    required
                />
            </FormControl>

            <div>
                <LoadingButton
                    sx={{marginRight: "1rem"}}
                    variant="contained"
                    type="submit"
                    loadingPosition="start"
                    disabled={buttonDisabled}
                    startIcon={<SaveIcon/>}
                >
                    <span>Add New Product</span>
                </LoadingButton>

                <Button variant="contained" color="warning" onClick={() => navigate(-1)}>
                    Back
                </Button>
                {!!snackbar && (
                    <Snackbar
                        open
                        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                        onClose={handleCloseSnackbar}
                        autoHideDuration={3000}
                    >
                        <Alert {...snackbar} onClose={handleCloseSnackbar}/>
                    </Snackbar>
                )}
            </div>
        </Box>
    );
};

export default BaseProductForm;
