import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {Home, Menu as MenuIcon} from '@mui/icons-material'
import ListItemIcon from '@mui/material/ListItemIcon';
import StorageRoundedIcon from '@mui/icons-material/StorageRounded';
import PublishedWithChangesRoundedIcon from '@mui/icons-material/PublishedWithChangesRounded';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import Divider from '@mui/material/Divider';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import {AppBar, Button, Toolbar, useMediaQuery} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";
import LocalStorageService from "../services/localStorageService";
import ProjectService from "../services/project.service";
import EventBus from "../common/EventBus";
import AuthService from "../services/auth.service";
import {blue, deepOrange} from "@mui/material/colors";
import HowToRegRoundedIcon from '@mui/icons-material/HowToRegRounded';
import VpnKeyRoundedIcon from '@mui/icons-material/VpnKeyRounded';
import {v4 as uuidv4} from 'uuid';

function convertToAbbreviation(input) {
    if (input === undefined) {
        return null
    } else {
    // Split the input string by underscores and get the first character of each word
    const words = input.split('_');
    return words.map(word => word.charAt(0)).join('') + "-Office" ;
    }
}

export default function NavigationBar() {
    const [state, setState] = useState({
        showModeratorBoard: false,
        showAdminBoard: false,
        currentUser: undefined,
        currentUserCompanyName: undefined,
        currentProjectName: undefined,
        projectRoleButtonIsDisabled: false,
        projectRoleButtonName: undefined
    });
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    const isNonMobileDevice = useMediaQuery("(min-width: 1000px)");

    const logout = useCallback(() => {
        setAnchorEl(null);
        AuthService.logout();
        setState((prevState) => ({
            ...prevState,
            currentUser: undefined,
            currentUserCompanyName: undefined,
            currentProjectName: undefined,
            showAdminBoard: false,
            showProjectAdminBoard: false,
            showCompanyAdminBoard: false,
            projectRoleButtonIsDisabled: false,
            projectRoleButtonName: undefined,
            companyProjectRoleType: undefined
        }));
        navigate('/login');
    }, [navigate]);

    function findCompanyProjectRoleType(companyMonogram, dataList) {
        const entry = dataList.find(item => item.companyMonogram === companyMonogram);

        if (entry) {
            return entry.companyProjectRoleType;
        } else {
            return "Company not found";
        }
    }

    useEffect(() => {
        const user = LocalStorageService.getUser();
        const companyName = LocalStorageService.getCompanyMonogram();

        if (user) {
            setState((prevState) => ({
                ...prevState,
                currentUser: user,
                currentUserCompanyName: companyName.companyNameMonogram,
                showAdminBoard: user.roles.includes('ROLE_ADMIN'),
                showProjectAdminBoard: user.roles.includes('ROLE_PR_ADMIN'),
                showCompanyAdminBoard: user.roles.includes('ROLE_CO_ADMIN'),

            }));
        }

        try {
            let company = LocalStorageService.getCompanyMonogram();
            if (company) {
                if (company !== '' && company.projects.length > 0) {
                    ProjectService.getProjectByMonogram(company.projects[0].projectMonogram)
                        .then((projectDetails) => {
                            setState((prevState) => ({
                                ...prevState,
                                currentProjectName: projectDetails.projectMonogram,
                                projectRoleButtonName: findCompanyProjectRoleType(company.companyNameMonogram, projectDetails.companies),
                                companyProjectRoleType: findCompanyProjectRoleType(company.companyNameMonogram, projectDetails.companies)
                            }));
                            LocalStorageService.setCompanyProjectRoleType(findCompanyProjectRoleType(company.companyNameMonogram, projectDetails.companies));
                        });
                }
            }

        } catch (e) {
            console.log(e);
        }

        const logoutHandler = () => {
            logout();
        };

        EventBus.on('logout', logoutHandler);

        return () => {
            EventBus.remove('logout', logoutHandler);
        };
    }, [logout]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };


    const {
        currentUser,
        currentUserCompanyName,
        currentProjectName,
        showAdminBoard,
        showProjectAdminBoard,
        showCompanyAdminBoard,
        projectRoleButtonIsDisabled,
        projectRoleButtonName,
        companyProjectRoleType
    } = state;

    const dashBoardButtonIsDisabled = (currentUser && currentUserCompanyName && currentProjectName);

    function stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    function stringAvatar(name) {
        return {
            sx: {
                bgcolor: stringToColor(name),
                width: 32,
                height: 32
            },
            children: `${name.split(' ')[0][0].toUpperCase()}`,
        };
    }

    const menuItems = [];


    if (currentUser) {
        menuItems.push(
            <Tooltip title="User Profile" placement="left" key={uuidv4()}>
                <Link
                    key="profile"
                    style={{color: "#2b2b2b", textDecoration: "none", minWidth: 100}}
                    to={"/profile"}
                >
                    <MenuItem onClick={handleClose}>
                        {currentUser && <Avatar/>}
                        {currentUser && `${currentUser.username.charAt(0).toUpperCase()}${currentUser.username.slice(1)}`}
                    </MenuItem>
                    {!currentProjectName && !currentUserCompanyName && <Divider key={uuidv4()}/>}
                </Link>
            </Tooltip>
        );

        if (currentUserCompanyName) {
            menuItems.push(
                <Tooltip title="Company Profile" placement="left" key={uuidv4()}>
                    <Link
                        key="my-company"
                        style={{color: "#2b2b2b", textDecoration: "none", minWidth: 100}}
                        to={"/my-company"}
                    >
                        <MenuItem onClick={handleClose}>
                            {currentUserCompanyName && (

                                <Avatar sx={{width: 32, height: 32, bgcolor: deepOrange[500]}}>C</Avatar>

                            )}
                            {currentUserCompanyName && `${currentUserCompanyName.charAt(0).toUpperCase()}${currentUserCompanyName.slice(1)}`}
                        </MenuItem>

                        {!currentProjectName && <Divider key={uuidv4()}/>}
                    </Link>
                </Tooltip>
            );
        }

        if (currentProjectName) {
            menuItems.push(
                <Tooltip title="Project Profile" placement="left" key={uuidv4()}>
                    <Link
                        key="my-project"
                        style={{color: "#2b2b2b", textDecoration: "none", minWidth: 100}}
                        to={"/my-project"}
                    >

                        <MenuItem onClick={handleClose}>
                            {currentProjectName && (

                                <Avatar sx={{width: 32, height: 32, bgcolor: blue[500]}}>P</Avatar>

                            )}
                            {currentProjectName && `${currentProjectName.charAt(0).toUpperCase()}${currentProjectName.slice(1)}`}
                        </MenuItem>

                        <Divider key={uuidv4()}/>
                    </Link>
                </Tooltip>
            );
        }

        if (showAdminBoard) {
            // showAdminBoard
            menuItems.push(
                <Link
                    key="admin"
                    style={{color: "#2b2b2b", textDecoration: "none", minWidth: 100}}
                    to={"/admin"}
                >
                    <MenuItem onClick={handleClose} key="admin-menu-item">
                        <ListItemIcon>
                            <SupervisorAccountIcon/>
                        </ListItemIcon>
                        Admin Board
                    </MenuItem>
                    <Divider key={uuidv4()}/>
                </Link>
            );
        }

        menuItems.push(
            <Link key="home" style={{color: "#2b2b2b", textDecoration: "none", minWidth: 100}} to={"/"}>
                <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                        <Home color='rgba(255,255,255,.8)'/>
                    </ListItemIcon>
                    Home
                </MenuItem>
            </Link>
        );

        if (dashBoardButtonIsDisabled) {
            // menuItems.push(
            //     <Link
            //         key="office"
            //         style={{color: "#2b2b2b", textDecoration: "none", minWidth: 100}}
            //         to={"/office"}
            //     >
            //         <MenuItem onClick={handleClose}>
            //             <ListItemIcon>
            //                 <StorageRoundedIcon/>
            //             </ListItemIcon>
            //             Office
            //         </MenuItem>
            //     </Link>
            // );
            menuItems.push(
                <Link
                    key="companyOffice"
                    style={{color: "#2b2b2b", textDecoration: "none", minWidth: 100}}
                    to={"/gc"}
                >
                    <MenuItem onClick={handleClose}>
                        <ListItemIcon>
                            <StorageRoundedIcon/>
                        </ListItemIcon>
                        {convertToAbbreviation(projectRoleButtonName)}
                    </MenuItem>
                </Link>
            );
            if (companyProjectRoleType === 'GENERAL_CONTRACTOR') {
                menuItems.push(
                    <Link
                        key="onsite"
                        style={{color: "#2b2b2b", textDecoration: "none", minWidth: 100}}
                        to={"/onsite"}
                    >
                        <MenuItem onClick={handleClose}>
                            <ListItemIcon>
                                <PublishedWithChangesRoundedIcon/>
                            </ListItemIcon>
                            OnSite
                        </MenuItem>
                        <Divider key={uuidv4()}/>
                    </Link>
                );
            }


        } else {
            menuItems.push(
                <Link
                    key="dashboard"
                    style={{color: "#2b2b2b", textDecoration: "none", minWidth: 100}}
                    to={"/dashboard"}
                >
                    <MenuItem onClick={handleClose}>
                        <ListItemIcon>
                            <DashboardRoundedIcon/>
                        </ListItemIcon>
                        Dashboard
                    </MenuItem>
                    <Divider key={uuidv4()}/>
                </Link>
            );
        }

        menuItems.push(
            <MenuItem key="logout" onClick={logout}>
                <ListItemIcon>
                    <Logout fontSize="small"/>
                </ListItemIcon>
                Logout
            </MenuItem>
        );

    } else {
        menuItems.push(
            <Link
                key="login"
                style={{color: "#2b2b2b", textDecoration: "none", minWidth: 100}}
                to={"/login"}
            >
                <MenuItem onClick={logout}>
                    <ListItemIcon>
                        <VpnKeyRoundedIcon fontSize="small"/>
                    </ListItemIcon>
                    Login
                </MenuItem>
            </Link>
        );

        menuItems.push(
            <Link
                key="signup"
                style={{color: "#2b2b2b", textDecoration: "none", minWidth: 100}}
                to={"/signup"}
            >
                <MenuItem onClick={logout}>
                    <ListItemIcon>
                        <HowToRegRoundedIcon fontSize="small"/>
                    </ListItemIcon>
                    Sign Up
                </MenuItem>
            </Link>
        );
    }
    const fullScreenMenuItems = [];

    if (currentUser) {
        // User Profile
        fullScreenMenuItems.push(
            <Tooltip title="User Profile" placement="left" key="user-profile-tooltip">
                <Link
                    style={{color: "#2b2b2b", textDecoration: "none", minWidth: 100}}
                    to={"/profile"}
                    key="user-profile-link"
                >
                    <MenuItem onClick={handleClose} key="user-profile-menu-item">
                        <Avatar/>
                        {currentUser && (`${currentUser.username.charAt(0).toUpperCase()}${currentUser.username.slice(1)}`)}
                    </MenuItem>
                    {(!currentProjectName && !currentUserCompanyName) && <Divider key={uuidv4()}/>}
                </Link>
            </Tooltip>
        );
    }

    if (currentUserCompanyName) {
        // Company Profile
        fullScreenMenuItems.push(
            <Tooltip title="Company Profile" placement="left" key="company-profile-tooltip">
                <Link
                    key="my-company"
                    style={{color: "#2b2b2b", textDecoration: "none", minWidth: 100}}
                    to={"/my-company"}
                >
                    <MenuItem onClick={handleClose} key="company-profile-menu-item">
                        {currentUserCompanyName && (
                            <Avatar sx={{
                                width: 32,
                                height: 32,
                                bgcolor: deepOrange[500]
                            }}>C</Avatar>
                        )}
                        {currentUserCompanyName && `${currentUserCompanyName.charAt(0).toUpperCase()}${currentUserCompanyName.slice(1)}`}
                    </MenuItem>
                    {!currentProjectName && <Divider key={uuidv4()}/>}
                </Link>
            </Tooltip>
        );
    }

    if (currentProjectName) {
        // Project Profile
        fullScreenMenuItems.push(
            <Tooltip title="Project Profile" placement="left" key="project-profile-tooltip">
                <Link
                    key="my-project"
                    style={{color: "#2b2b2b", textDecoration: "none", minWidth: 100}}
                    to={"/my-project"}
                >
                    <MenuItem onClick={handleClose} key="project-profile-menu-item">
                        {currentProjectName && (
                            <Avatar sx={{
                                width: 32,
                                height: 32,
                                bgcolor: blue[500]
                            }}>P</Avatar>
                        )}
                        {currentProjectName && `${currentProjectName.charAt(0).toUpperCase()}${currentProjectName.slice(1)}`}
                    </MenuItem>
                    <Divider key={uuidv4()}/>
                </Link>
            </Tooltip>
        );
    }


// Logout
    fullScreenMenuItems.push(
        <MenuItem onClick={logout} key="logout-menu-item">
            <ListItemIcon>
                <Logout fontSize="small"/>
            </ListItemIcon>
            Logout
        </MenuItem>
    );

    return (

        <AppBar sx={{
            p: "0 2%"
            , boxShadow: 2
        }}
        >
            <Toolbar
                sx={{justifyContent: "space-between"}}
            >
                <h3><Link style={{color: "#fff", textDecoration: "none",}}
                          to={"/"}>MyStock</Link></h3>
                <React.Fragment>
                    {isNonMobileDevice ?
                        <>
                            <Box sx={{display: 'flex', alignItems: 'center', textAlign: 'center'}}>
                                {!projectRoleButtonIsDisabled && (
                                    <Button key="projectRoleButtonIsDisabled-button">
                                        <Link
                                            style={{color: "#fff", textDecoration: "none", minWidth: 100}}
                                            to={"/gc"}
                                        >
                                            {convertToAbbreviation(projectRoleButtonName)}
                                        </Link>
                                    </Button>
                                )}
                                {showProjectAdminBoard && (
                                    <Button key="project-admin-button">
                                        <Link
                                            style={{color: "#fff", textDecoration: "none", minWidth: 100}}
                                            to={"/projectadmin"}
                                        >
                                            Project Admin Board
                                        </Link>
                                    </Button>
                                )}
                                {showCompanyAdminBoard && (
                                    <Typography sx={{minWidth: 100}}>
                                        <Link to="/companyadmin" key="company-admin-link">
                                            Company Admin Board
                                        </Link>
                                    </Typography>
                                )}
                                {showAdminBoard && (
                                    <Button key="admin-button">
                                        <Link
                                            style={{color: "#fff", textDecoration: "none", minWidth: 100}}
                                            to={"/admin"}
                                        >
                                            Admin Board
                                        </Link>
                                    </Button>
                                )}
                                {(currentUser && !dashBoardButtonIsDisabled) && (
                                    <Tooltip title="Manage Companies & Projects" key={uuidv4()}>
                                        <Button key="dashboard-button">
                                            <Link
                                                style={{color: "#fff", textDecoration: "none", minWidth: 100}}
                                                to={"/dashboard"}
                                            >
                                                Dashboard
                                            </Link>
                                        </Button>
                                    </Tooltip>
                                )}
                                {/*{dashBoardButtonIsDisabled ? (*/}
                                {/*    <Tooltip title="Administration" key={uuidv4()}>*/}
                                {/*        <Button key="office-button">*/}
                                {/*            <Link*/}
                                {/*                style={{color: "#fff", textDecoration: "none", minWidth: 100}}*/}
                                {/*                to={"/office"}*/}
                                {/*            >*/}
                                {/*                Office*/}
                                {/*            </Link>*/}
                                {/*        </Button>*/}
                                {/*    </Tooltip>*/}
                                {/*) : (*/}
                                {/*    ''*/}
                                {/*)}*/}
                                {dashBoardButtonIsDisabled ? (
                                    companyProjectRoleType === 'GENERAL_CONTRACTOR' && (
                                        <Tooltip title="Handover Products" key={uuidv4()}>
                                            <Button key="onsite-button">
                                                <Link
                                                    style={{color: "#fff", textDecoration: "none", minWidth: 100}}
                                                    to={"/onsite"}
                                                >
                                                    OnSite
                                                </Link>
                                            </Button>
                                        </Tooltip>
                                    )
                                ) : (
                                    ''
                                )}
                                {currentUser ? (
                                    <>
                                        <Tooltip title="Menu">
                                            <Box sx={{display: 'flex', alignItems: 'center', textAlign: 'center'}}>
                                                <IconButton
                                                    sx={{color: '#fff'}}
                                                    id="basic-button"
                                                    aria-controls={open ? 'account-menu' : undefined}
                                                    aria-haspopup="true"
                                                    aria-expanded={open ? 'true' : undefined}
                                                    onClick={handleClick}
                                                >
                                                    {currentUser && (
                                                        <Avatar {...stringAvatar(currentUser.username)} />
                                                    )}
                                                </IconButton>
                                            </Box>
                                        </Tooltip>
                                    </>
                                ) : (
                                    <>
                                        <Tooltip title="Login" key={uuidv4()}>
                                            <Button key="login-button">
                                                <Link
                                                    style={{color: "#fff", textDecoration: "none", minWidth: 100}}
                                                    to={"/login"}
                                                >
                                                    Login
                                                </Link>
                                            </Button>
                                        </Tooltip>
                                        <Tooltip title="Register New Account" key={uuidv4()}>
                                            <Button key="signup-button">
                                                <Link
                                                    style={{color: "#fff", textDecoration: "none", minWidth: 100}}
                                                    to={"/signup"}
                                                >
                                                    Sign Up
                                                </Link>
                                            </Button>
                                        </Tooltip>
                                    </>
                                )}
                            </Box>
                            <Menu
                                anchorEl={anchorEl}
                                id="account-menu"
                                open={open}
                                onClose={handleClose}
                                onClick={handleClose}
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 1.5,
                                        '& .MuiAvatar-root': {
                                            width: 32,
                                            height: 32,
                                            ml: -0.5,
                                            mr: 1,
                                        },
                                        '&:before': {
                                            content: '""',
                                            display: 'block',
                                            position: 'absolute',
                                            top: 0,
                                            right: 14,
                                            width: 10,
                                            height: 10,
                                            bgcolor: 'background.paper',
                                            transform: 'translateY(-50%) rotate(45deg)',
                                            zIndex: 0,
                                        },
                                    },
                                }}
                                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                            >
                                {fullScreenMenuItems}
                            </Menu>
                        </>
                        :
                        <>
                            <Box sx={{display: 'flex', alignItems: 'center', textAlign: 'center'}}>
                                <IconButton
                                    sx={{color: '#fff'}}
                                    id="basic-button"
                                    aria-controls={open ? 'account-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClick}
                                >
                                    <MenuIcon/>
                                </IconButton>
                            </Box>
                            <Menu
                                anchorEl={anchorEl}
                                id="account-menu"
                                open={open}
                                onClose={handleClose}
                                onClick={handleClose}
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 1.5,
                                        '& .MuiAvatar-root': {
                                            width: 32,
                                            height: 32,
                                            ml: -0.5,
                                            mr: 1,
                                        },
                                        '&:before': {
                                            content: '""',
                                            display: 'block',
                                            position: 'absolute',
                                            top: 0,
                                            right: 14,
                                            width: 10,
                                            height: 10,
                                            bgcolor: 'background.paper',
                                            transform: 'translateY(-50%) rotate(45deg)',
                                            zIndex: 0,
                                        },
                                    },
                                }}
                                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                            >
                                {menuItems}
                            </Menu>
                        </>
                    }
                </React.Fragment>
            </Toolbar>
        </AppBar>
    );

}
