import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './App.css'
import App2 from './App2';
import {BrowserRouter} from "react-router-dom";
import registerServiceWorker from "./serviceWorkerRegistration";


import ToggleColorMode from "./ToggleColorMode";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <App2 />
  </BrowserRouter>
);

const footer = ReactDOM.createRoot(document.getElementById('footer'));
footer.render(
    <footer className="page-footer font-small blue">
        <div className="footer-copyright text-center py-1" style={{fontSize: '14px'}}>
            © 2023 Copyright: Fülöp Dénes
        </div>
    </footer>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// serviceWorkerRegistration.register();

// registerServiceWorker();
