import React, {Component} from 'react';
import {Container, Form, Button, Card, Row, Col} from 'react-bootstrap';
import {ProjectType, CompanyProjectRoleType} from '../common/enums'
import './project-register2.css';
import LocalStorageService from "../services/localStorageService";
import ProjectService from "../services/project.service";
import {withRouter} from '../common/with-router';

class CreateProjectForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            project: {
                name: '',
                type: '',
                monogram: '',
                hasOfficialAddress: false,
                street: '',
                buildingId: '',
                postalCode: '',
                city: '',
                country: '',
                plotNumber: '',
            },
            company: {
                monogram: null,
                companyProjectRoleType: '',
            },
            user: {
                projectAdminUsername: '',
            },
            userReady: false
        };
    }

    componentDidMount() {
        const user = LocalStorageService.getUser().username;
        const companyMonogram = LocalStorageService.getCompanyMonogram();
        this.setState((prevState) => ({
            ...prevState,
            company: {
                ...prevState.company,
                monogram: companyMonogram.companyNameMonogram
            },
            user: {
                ...prevState.user,
                projectAdminUsername: user
            },
            userReady: true
        }));
    }

    handleProjectChange = (e) => {
        const {name, value} = e.target;
        this.setState((prevState) => ({
            ...prevState,
            project: {
                ...prevState.project,
                [name]: value,
            },
        }));
    };

    handleCompanyChange = (e) => {
        const {name, value} = e.target;
        this.setState((prevState) => ({
            ...prevState,
            company: {
                ...prevState.company,
                [name]: value,
            },
        }));
    };

    handleCheckboxChange = () => {
        this.setState((prevState) => ({
            project: {
                ...prevState.project,
                hasOfficialAddress: !prevState.project.hasOfficialAddress,
                plotNumber: '',
                additionalInfoToGetThere: '',
            },
        }));
    };

    handleSubmit = (e) => {
        e.preventDefault();
        console.log(this.state);
        try {
            ProjectService.registerNewProjectWithCompanyAndUser(this.state.project, this.state.company, this.state.user).then(
                (response) => {
                    LocalStorageService.setCurrentProject(response)
                    this.props.router.navigate("/my-project");
                    window.location.reload();
                },
                error => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                    console.log("resMessage: " + resMessage);
                }
            );

        } catch (error) {
            console.error('Error:', error);
        }
    };

    renderAddressFields = () => {
        const {project} = this.state;
        if (project.hasOfficialAddress) {
            return (
                <>

                    <Row>
                        <Col md={12}>
                            <Form.Group controlId="street">
                                <Form.Control
                                    type="text"
                                    placeholder="Street Name and Type"
                                    name="street"
                                    value={project.street}
                                    onChange={this.handleProjectChange}
                                    required
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12}>
                            <Form.Group controlId="buildingId">
                                <Form.Control
                                    type="text"
                                    placeholder="Building nr."
                                    name="buildingId"
                                    value={project.buildingId}
                                    onChange={this.handleProjectChange}
                                    required
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </>
            );
        }

        return (
            <>
                <Row>
                    <Col md={12}>
                        <Form.Group controlId="street">
                            <Form.Control
                                type="text"
                                placeholder="Nearest Street Name and Type"
                                name="street"
                                value={project.street}
                                onChange={this.handleProjectChange}
                                required
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Form.Group controlId="buildingId">
                            <Form.Control
                                type="text"
                                placeholder="Nearest Building nr."
                                name="buildingId"
                                value={project.buildingId}
                                onChange={this.handleProjectChange}
                                required
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Form.Group controlId="plotNumber">
                            <Form.Control
                                type="text"
                                placeholder="Plot Number"
                                name="plotNumber"
                                value={project.plotNumber}
                                onChange={this.handleProjectChange}
                                required
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Form.Group controlId="additionalInfoToGetThere">
                            <Form.Control
                                type="text"
                                placeholder="Additional Info to Get There"
                                name="additionalInfoToGetThere"
                                value={project.additionalInfoToGetThere}
                                onChange={this.handleProjectChange}
                                required
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </>
        );
    };

    render() {
        const {project, company} = this.state;

        return (
            <Container>
                {(this.state.userReady) ?
                    <div>
                        <Card style={{minWidth: '315px'}}>
                            <Card.Header as="h1" className="text-center">
                                Create New Project
                            </Card.Header>

                            <Form id="myForm" onSubmit={this.handleSubmit}>
                                <Row>
                                    <Col md={12}>
                                        <Form.Group controlId="name">
                                            <Form.Label>1. Project Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter project name"
                                                name="name"
                                                value={project.name}
                                                onChange={this.handleProjectChange}
                                                required
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <Form.Group controlId="monogram">
                                            <Form.Label>2. Project Name Monogram</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter max. 4 characters"
                                                name="monogram"
                                                value={project.monogram}
                                                onChange={this.handleProjectChange}
                                                required
                                                maxLength={4}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={12}>
                                        <Form.Group controlId="type">
                                            <Form.Label>3. Project Type</Form.Label>
                                            <Form.Control
                                                as="select"
                                                name="type"
                                                value={project.type}
                                                onChange={this.handleProjectChange}
                                                required
                                            >
                                                <option value="">Select project type</option>
                                                {Object.entries(ProjectType).map(([key, value]) => (
                                                    <option key={key} value={key}>
                                                        {value}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Card.Text as="h6" style={{marginTop: '10px'}}>
                                    4. Project Address:

                                    <Row style={{marginTop: '10px'}}>
                                        <Col md={12}>
                                            <Form.Group controlId="city">
                                                <Form.Control
                                                    type="text"
                                                    placeholder="City"
                                                    name="city"
                                                    value={project.city}
                                                    onChange={this.handleProjectChange}
                                                    required
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={12}>
                                            <Form.Group controlId="postalCode">
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Postal Code"
                                                    name="postalCode"
                                                    value={project.postalCode}
                                                    onChange={this.handleProjectChange}
                                                    required
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <Form.Group controlId="country">
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Country"
                                                    name="country"
                                                    value={project.country}
                                                    onChange={this.handleProjectChange}
                                                    required
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Form.Group controlId="hasOfficialAddress">
                                        <div className="form-check form-check-inline">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="hasOfficialAddress"
                                                name="hasOfficialAddress"
                                                checked={project.hasOfficialAddress}
                                                onChange={this.handleCheckboxChange}
                                            />
                                            <label className="form-check-label" htmlFor="hasOfficialAddress">
                                                Project has official address!
                                            </label>
                                        </div>
                                    </Form.Group>
                                </Card.Text>

                                {this.renderAddressFields()}

                                <Row>
                                    <Col md={12}>
                                        <Form.Group controlId="companyProjectRoleType">
                                            <Form.Label>5. <b>{this.state.company.monogram}</b> Company Role At This
                                                Project</Form.Label>
                                            <Form.Control
                                                as="select"
                                                name="companyProjectRoleType"
                                                value={company.companyProjectRoleType}
                                                onChange={this.handleCompanyChange}
                                                required
                                            >
                                                <option value="">Select Company Role Type</option>
                                                {Object.entries(CompanyProjectRoleType).map(([key, value]) => (
                                                    <option key={key} value={key}>
                                                        {value}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <div className="text-center" style={{marginTop: '10px'}}>
                                    <Button
                                        variant="primary"
                                        type="submit"
                                        style={{
                                            width: '130px',
                                            margin: '0 5px',
                                        }}
                                    >
                                        Create Project
                                    </Button>

                                    <Button
                                        variant="secondary"
                                        onClick={() => this.props.router.navigate("/dashboard")}
                                        style={{
                                            width: '130px',
                                            margin: '10px',
                                        }}
                                    >
                                        Back
                                    </Button>
                                </div>
                            </Form>
                        </Card>
                    </div> : null}
            </Container>
        );
    }
}

export default withRouter(CreateProjectForm);
