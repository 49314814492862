import React, {useEffect, useState} from 'react';
import {Box, SpeedDial, SpeedDialIcon, Typography} from "@mui/material";
import DefaultDataGrid from "../../components/grid/DefaultDataGrid";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import {styled} from "@mui/material/styles";
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import EditIcon from '@mui/icons-material/Edit';
import CircularIndeterminateLoading from "../../components/loading/CircularIndeterminateLoading";
import {Link} from "react-router-dom";
import ErrorPage from "../Error/ErrorPage";
import ToggleButton from '@mui/material/ToggleButton';
import { ToggleButtonGroup } from '@mui/material';
import DetailedDataGrid from "../../components/grid/DetailedDataGrid";
import EditableDataCrudGrid from "../../components/grid/EditableDataCrudGrid";
import EditableDataGrid from "../../components/grid/EditableDataGrid";
import { useNavigate } from 'react-router-dom';

const StyledSpeedDial = styled(SpeedDial)(({theme}) => ({
    position: 'absolute',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
        top: theme.spacing(2),
        left: theme.spacing(2),
    },
    "& svg": {
        display: "block"
    },
}));

const withLink = (to, children) => <Link to={to}>{children}</Link>;

const actions = [
    {icon: withLink("/office-edit", <EditIcon sx={{color: "#000"}}/>), name: 'Edit Mode'},
    // { icon: withLink("/delete", <DeleteIcon sx={{ color: red[500] }} />), name: 'Delete Element'},
    {icon: withLink("/new", <AddCircleOutlineRoundedIcon color="primary"/>), name: 'Add new element'},
    {icon: <DocumentScannerIcon color="disabled"/>, name: 'Scan document'}
];

// const fetchProducts = (signal) => {
//     return fetch(`${process.env.REACT_APP_BASEURL}/products/all`, {signal})
//         .then((res) => {
//             return res.json();
//         });
// };

const Office = () => {
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState(null);
    const [alignment, setAlignment] = React.useState('default');

    const handleChange = (event, newAlignment) => {
        if (newAlignment === null) {
            setAlignment('default')
        } else {
            setAlignment(newAlignment);
        }

    };

    useEffect(() => {

        async function fetchData() {
            try {
                const response = await fetch(`${process.env.REACT_APP_BASEURL}/api/v1/products/all`); // Replace with
                // your API endpoint
                if (!response.ok) {
                    // Check if the response status code indicates an error
                    console.error('Network response was not ok');
                }
                const data = await response.json();
                // Process the data here
                setData(data);
                setIsLoading(false);
            } catch (error) {
                setError(error)
                if (error.message === 'Network response was not ok') {
                    // Handle specific error when the response status is not OK
                    console.error('The server returned an error.');
                } else if (error instanceof TypeError && error.message === 'Failed to fetch') {
                    // Handle the "Failed to fetch" error
                    console.error('Network request failed. Check your internet connection.');
                } else {
                    // Handle other errors
                    console.error('An error occurred:', error);
                }
                setIsLoading(false);
            }
        }

        fetchData();
    }, []);

    // if (error) {
    //     return <ErrorPage/>;
    // }

    if (isLoading) {
        return (
            <>
                <CircularIndeterminateLoading/>
            </>
        )
    } else if (error) {
        return <ErrorPage/>;
    } else {
        return (
            <div
                // className="item"
                style={{paddingLeft: '40px', paddingRight: '40px', minWidth:'355px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
                // , maxHeight: '150px'
                }}
            >
                {/*<Box sx={{position: 'relative', mt: 0, height: 80}}>*/}

                {/*    <StyledSpeedDial*/}
                {/*        ariaLabel="SpeedDial playground example"*/}
                {/*        icon={<SpeedDialIcon/>}*/}
                {/*        direction="right"*/}
                {/*    >*/}
                {/*        {actions.map((action) => (*/}
                {/*            <SpeedDialAction*/}
                {/*                key={action.name}*/}
                {/*                icon={action.icon}*/}
                {/*                tooltipTitle={action.name}*/}
                {/*            />*/}
                {/*        ))}*/}
                {/*    </StyledSpeedDial>*/}
                {/*</Box>*/}
                {alignment === 'default' && (<DefaultDataGrid
                    products={data}
                    // sx={{backgroundColor: '#fff'}}
                />)}
                {alignment === 'detailed' && (<DetailedDataGrid
                    products={data}
                    // sx={{backgroundColor: '#fff'}}
                />)}
                {alignment === 'editable' && (<EditableDataGrid
                    products={data}
                    // sx={{backgroundColor: '#fff'}}
                />)}

                <ToggleButtonGroup
                    color="primary"
                    value={alignment}
                    exclusive
                    onChange={handleChange}
                    aria-label="Platform"
                    sx={{mt: 1.5}}
                >
                    <ToggleButton value="default">Default</ToggleButton>
                    <ToggleButton value="detailed">Detailed</ToggleButton>
                    <ToggleButton value="editable">Editable</ToggleButton>
                </ToggleButtonGroup>
                {/*<Typography variant={"h8"} component={"div"} sx={{m: 0, p: 1, fontWeight: "bold"}}>*/}
                {/*    VIEW MODE*/}
                {/*</Typography>*/}
                {/*<div>*/}
                {/*   <b>{alignment}</b>*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*    Default a scrollbar mentes*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*    Detailed az jobbra balra huzogatos*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*    Grid alá action gomb nem*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*    100% széles táblázat*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*    kisképernyön is legyen kényeles ha lefelé kell görgetni*/}
                {/*</div>*/}
                {/*<div>*/}
                {/*    felső sor hamburger menube*/}
                {/*</div>*/}
            </div>
        )
    }
}

export default Office;
