import React, { Component } from "react";

import TestService from "../services/test.service";
import EventBus from "../common/EventBus";
import { withRouter } from '../common/with-router';
import {Link} from "react-router-dom";

class Session extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: ""
    };
  }

  componentDidMount() {
    TestService.getUserBoard().then(
      response => {
        this.setState({
          content: response.data
        });
      },
      error => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });

        if (error.response && error.response.status === 403 && error.response.status === 400) {
          this.props.router.navigate("/session")
          EventBus.dispatch("logout");
        }
      }
    );
  }

  render() {
    return (
      <div className="container">
        <header className="jumbotron" style={{textAlignVertical: "center", textAlign: "center"}}>
          <h3 style={{paddingBottom: "20px"}}>Login session was expired.</h3>
          <Link to={"/login"} style={{textDecoration: "none"}}>
            <button className="btn btn-primary btn-block" >Go To Login Page</button>
          </Link>
        </header>
      </div>
    );
  }
}
export default withRouter(Session);
