import * as React from 'react';
import {
    DataGrid,
    GridRowsProp,
    GridColDef,
    gridClasses,
    GridToolbar,
    useGridApiContext,
    gridPaginatedVisibleSortedGridRowIdsSelector,
    gridExpandedSortedRowIdsSelector,
    GridToolbarContainer,
    GridToolbarQuickFilter, GridToolbarColumnsButton, GridToolbarFilterButton
} from '@mui/x-data-grid';
import {useState} from "react";
import {useDemoData} from "@mui/x-data-grid-generator";
import {alpha, styled} from '@mui/material/styles';
import {Alert, Box, Button, Snackbar} from "@mui/material";
import {GridActionsCellItem, GridRowModes} from "@mui/x-data-grid-pro";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import AddIcon from "@mui/icons-material/Add";
import {Link} from "react-router-dom";

const useFakeMutation = () => {
    return React.useCallback(
        (product) =>
            new Promise((resolve, reject) => {
                setTimeout(() => {
                    if (product.relatedUnit === '') {
                        reject(new Error("Error while saving product: Related Unit can't be empty."));
                    } else {
                        resolve({...product, relatedUnit: product.relatedUnit});
                    }
                }, 200);
            }),
        [],
    );
};

const deleteProduct = (id) => {
    return fetch(`${process.env.REACT_APP_BASEURL}/api/v1/products/${id}`, {
        method: 'DELETE'
    }).then(() => {
        // console.log('removed');
    }).catch(err => {
        console.error(err)
    });
};


const ODD_OPACITY = 0.1;

const StripedDataGrid = styled(DataGrid)(({theme}) => ({
    [`& .${gridClasses.row}.even`]: {
        backgroundColor: theme.palette.grey[200],
        '&:hover, &.Mui-hovered': {
            backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
            '@media (hover: none)': {
                backgroundColor: 'transparent',
            },
        },
        '&.Mui-selected': {
            backgroundColor: alpha(
                theme.palette.primary.main,
                ODD_OPACITY + theme.palette.action.selectedOpacity,
            ),
            '&:hover, &.Mui-hovered': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    ODD_OPACITY +
                    theme.palette.action.selectedOpacity +
                    theme.palette.action.hoverOpacity,
                ),
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                    backgroundColor: alpha(
                        theme.palette.primary.main,
                        ODD_OPACITY + theme.palette.action.selectedOpacity,
                    ),
                },
            },
        },
    },
}));

function CustomToolbar() {
    const apiRef = useGridApiContext();

    const getRowsFromCurrentPage = ({ apiRef }) =>
        gridPaginatedVisibleSortedGridRowIdsSelector(apiRef);
    const getFilteredRows = ({ apiRef }) => gridExpandedSortedRowIdsSelector(apiRef);
    const handleExport = (options) => apiRef.current.exportDataAsCsv(options);

    return (
        <GridToolbarContainer>
            <GridToolbarQuickFilter printOptions={{ disableToolbarButton: true }} />
            <GridToolbarColumnsButton printOptions={{ disableToolbarButton: true }} />
            <Link to="/new" style={{ textDecoration: 'none' }}>
                <Button color="primary" size="small" startIcon={<AddIcon />}>
                    New Product
                </Button>
            </Link>
        </GridToolbarContainer>
    );
}


const EditableDataGrid = ({products}) => {
    // const { setRows, setRowModesModel } = props;
    const [tableData] = useState(products);
    // Map the data to rows
    const initialRowsFromTable: GridRowsProp = tableData.map((item) => ({
        id: item.id,
        buildingId: item.buildingId,
        relatedUnit: item.relatedUnit,
        roomNameOfInstallation: item.roomNameOfInstallation,
        categoryType: item.categoryType,
        productName: item.productName,
        productTechCode: item.productTechCode,
        quantity: item.quantity,
        quantityType: item.quantityType,
        deliveryNoteId: item.deliveryNoteId,
        deliveryType: item.deliveryType,
        timeOfRecord: item.timeOfRecord,
        timeOfOrder: item.timeOfOrder,
        timeOfArrivedAtSite: item.timeOfArrivedAtSite,
        placeOfStorage: item.placeOfStorage,
        storedBy: item.storedBy,
        timeOfInstalled: item.timeOfInstalled,
        roomPlanCode: item.roomPlanCode,
        lastTimeOfModified: item.lastTimeOfModified
    }));

    const [rows, setRows] = React.useState(initialRowsFromTable);
    const isEditable = true;
    const [rowModesModel, setRowModesModel] = React.useState({});

    const handleRowEditStart = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleEditClick = (id) => () => {
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.Edit}});
    };

    const handleSaveClick = (id) => () => {
        setRowModesModel({...rowModesModel, [id]: {mode: GridRowModes.View}});
    };

    const handleDeleteClick = (id) => () => {
        deleteProduct(id)
            .then(() => {
                // navigate("/employees");
            })
            .catch((err) => {
                throw err;
            })
            .finally(() => {
                // setLoading(false);
            });
        setRows(rows.filter((row) => row.id !== id));
        setSnackbar({children: 'Product successfully deleted', severity: 'success'});
    };

    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: {mode: GridRowModes.View, ignoreModifications: true},
        });

        // const editedRow = rows.find((row) => row.id === id);
        // if (editedRow.isNew) {
        //     setRows(rows.filter((row) => row.id !== id));
        // }
    };

    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    // Define the columns
    const columns :GridColDef[] = [
        {
            field: 'action',
            type: 'actions',
            headerName: 'Modify',
            width: 80,
            cellClassName: 'action',
            getActions: ({id}) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon/>}
                            label="Save"
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon/>}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon/>}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon/>}
                        label="Delete"
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
        {field: 'buildingId', headerName: 'Building', width: 50, editable: false},
        {field: 'relatedUnit', headerName: 'Related Unit', width: 100, editable: false},
        {field: 'roomNameOfInstallation', headerName: 'Room Name', width: 150, editable: false},
        {field: 'categoryType', headerName: 'Category', width: 150, editable: isEditable},
        {field: 'productName', headerName: 'Product Name', width: 150, editable: false},
        {field: 'productTechCode', headerName: 'Tech. Code', width: 150, editable: isEditable},
        {field: 'quantity', headerName: 'Quantity', width: 100, editable: isEditable},
        {field: 'quantityType', headerName: 'Quantity Type', width: 100, editable: isEditable},
        {field: 'deliveryNoteId', headerName: 'DeliveryNote Id', width: 150, editable: isEditable},
        {field: 'deliveryType', headerName: 'Delivery Type', width: 150, editable: isEditable},
        {field: 'timeOfRecord', headerName: 'Time of Record', width: 230, editable: isEditable},
        {field: 'timeOfOrder', headerName: 'Ordered At', width: 230, editable: isEditable},
        {field: 'timeOfArrivedAtSite', headerName: 'Arrived At', width: 230, editable: isEditable},
        {field: 'placeOfStorage', headerName: 'Store at', width: 150, editable: isEditable},
        {field: 'storedBy', headerName: 'Stored By', width: 150, editable: isEditable},
        {field: 'timeOfInstalled', headerName: 'Installed', width: 150, editable: isEditable},
        {field: 'roomPlanCode', headerName: 'Room ID', width: 150, editable: isEditable},
        {field: 'lastTimeOfModified', headerName: 'Modified At', width: 230, editable: isEditable},
    ];


    const mutateRow = useFakeMutation();

    const [snackbar, setSnackbar] = React.useState(null);

    const handleCloseSnackbar = () => setSnackbar(null);

    const processRowUpdate = React.useCallback(
        async (newRow) => {
            // Make the HTTP request to save in the backend
            const updatedProduct = await mutateRow(newRow);
            const res = await fetch(`${process.env.REACT_APP_BASEURL}/api/v1/products/${updatedProduct.id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(newRow)
            })
            const response = await res.json();
            setSnackbar({children: 'Modification successfully saved', severity: 'success'});
            return response;
        },
        [mutateRow],
    );

    const handleProcessRowUpdateError = React.useCallback((error) => {
        setSnackbar({children: error.message, severity: 'error'});
    }, []);

    const StyledGridOverlay = styled('div')(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        '& .ant-empty-img-1': {
            fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
        },
        '& .ant-empty-img-2': {
            fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
        },
        '& .ant-empty-img-3': {
            fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
        },
        '& .ant-empty-img-4': {
            fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
        },
        '& .ant-empty-img-5': {
            fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
            fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
        },
    }));

    function CustomNoRowsOverlay() {
        return (
            <StyledGridOverlay>
                <svg
                    width="120"
                    height="100"
                    viewBox="0 0 184 152"
                    aria-hidden
                    focusable="false"
                >
                    <g fill="none" fillRule="evenodd">
                        <g transform="translate(24 31.67)">
                            <ellipse
                                className="ant-empty-img-5"
                                cx="67.797"
                                cy="106.89"
                                rx="67.797"
                                ry="12.668"
                            />
                            <path
                                className="ant-empty-img-1"
                                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                            />
                            <path
                                className="ant-empty-img-2"
                                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                            />
                            <path
                                className="ant-empty-img-3"
                                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                            />
                        </g>
                        <path
                            className="ant-empty-img-3"
                            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                        />
                        <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
                            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
                            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
                        </g>
                    </g>
                </svg>
                <Box sx={{ mt: 1 }}>No Rows</Box>
            </StyledGridOverlay>
        );
    }

    const {loading} = useDemoData({});

    return (
        <>

            {/*<div*/}
            {/*    style={{height: 495, width: '100%', backgroundColor: '#fff', borderRadius: '6px', display: 'flex'}}*/}
            {/*>*/}
            {/*    <DataGrid*/}
            {/*        columns={columns}*/}
            {/*        rows={rows}*/}
            {/*        density="compact"*/}
            {/*        // slots={{toolbar: GridToolbar}}*/}
            {/*        slots={{*/}
            {/*            toolbar: GridToolbar,*/}
            {/*        }}*/}
            {/*        // sx={{*/}
            {/*        //     boxShadow: 1,*/}
            {/*        //     border: 1,*/}
            {/*        //     borderColor: 'primary.light',*/}
            {/*        //     '& .MuiDataGrid-cell:hover': {*/}
            {/*        //         color: 'primary.main',*/}
            {/*        //     },*/}
            {/*        // }}*/}
            {/*    />*/}

            {/*    <DataGrid*/}
            {/*        columns={columns}*/}
            {/*        rows={rows}*/}
            {/*        // slotProps={{*/}
            {/*        //     row: {*/}
            {/*        //         onContextMenu: handleContextMenu,*/}
            {/*        //         style: { cursor: 'context-menu' },*/}
            {/*        //     },*/}
            {/*        // }}*/}
            {/*    />*/}
            {/*</div>*/}


            <div style={{
                minHeight: 400
                , height: '75vh'
                , width: '100%'
                ,
                backgroundColor: '#fff', borderRadius: '6px'
                // , maxWidth: '515px'
                , minWidth: '250px',
            }}>
                <StripedDataGrid
                    loading={loading}
                    slots={{
                        noRowsOverlay: CustomNoRowsOverlay,
                        // toolbar: GridToolbar
                        toolbar: CustomToolbar
                    }}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 20 },
                        },
                    }}
                    pageSizeOptions={[20, 25, 50]}
                    rows={rows}
                    columns={columns}
                    sx={{
                        boxShadow: 1,
                        border: 1,
                        borderColor: 'primary.light',
                        '& .MuiDataGrid-cell:hover': {
                            color: 'primary.main',
                        },

                        '& .MuiDataGrid-cellContent': {
                            color: '#636363',
                            // bgcolor: (theme) =>
                            //     theme.palette.mode === 'dark' ? '#858585' : 'rgb(205 205 205)',
                        },
                        // '& .MuiDataGrid-row--editing .MuiDataGrid-cellContent': {
                        //     color: '#636363',
                        //     // bgcolor: (theme) =>
                        //     //     theme.palette.mode === 'dark' ? '#858585' : 'rgb(205 205 205)',
                        // },

                        '& .MuiDataGrid-cell--editable .MuiDataGrid-cellContent': {
                            color: '#000000',
                            // bgcolor: (theme) =>
                            //     theme.palette.mode === 'dark' ? '#376331' : 'rgb(255 255 255)',
                        },

                        // '& .MuiDataGrid-row.even .MuiDataGrid-cell--editable': {
                        //     color: '#ff0202',
                        //     // bgcolor: (theme) =>
                        //     //     theme.palette.mode === 'dark' ? '#858585' : 'rgb(205 205 205)',
                        // },

                        '& .MuiDataGrid-cell--editable': {
                            // color: '#000000',
                            bgcolor: (theme) =>
                                theme.palette.mode === 'dark' ? '#376331' : 'rgb(186 243 168 / 25%)',
                        },

                        '& .MuiDataGrid-cell.MuiDataGrid-cell--editing': {
                            bgcolor: (theme) =>
                                theme.palette.mode === 'dark' ? '#376331' : 'rgb(186 243 168)',
                        },
                    }}
                    getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                    }
                    onProcessRowUpdateError={handleProcessRowUpdateError}
                    editMode="row"
                    rowModesModel={rowModesModel}
                    onRowModesModelChange={handleRowModesModelChange}
                    onRowEditStart={handleRowEditStart}
                    onRowEditStop={handleRowEditStop}
                    density="compact"
                    processRowUpdate={processRowUpdate}
                />
                {!!snackbar && (
                    <Snackbar
                        open
                        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                        onClose={handleCloseSnackbar}
                        autoHideDuration={3000}
                    >
                        <Alert {...snackbar} onClose={handleCloseSnackbar}/>
                    </Snackbar>
                )}
            </div>
        </>
    );
}
export default EditableDataGrid;
