import axios from "axios";
import LocalStorageService from "./localStorageService";
import jwtInterceptor from "./jwtInterceptor";

const API_URL = `${process.env.REACT_APP_BASEURL}/api/v1/project`;

axios.defaults.withCredentials = true

const registerNewProjectWithCompanyAndUser = (project, company, user) => {
    const dto = {
        projectName: project.name,
        projectMonogram: project.monogram,
        projectType: project.type,

        city: project.city,
        country: project.country,
        postalCode: project.postalCode,

        street: project.street,
        buildingId: project.buildingId,
        hasOfficialAddress: project.hasOfficialAddress,

        plotNumber: project.plotNumber,
        additionalInfoToGetThere: project.additionalInfoToGetThere,

        companyMonogram: company.monogram,
        companyProjectRoleType: company.companyProjectRoleType,

        projectAdminUsername: user.projectAdminUsername
    }
    return jwtInterceptor.post(`${API_URL}/register`, dto).then((response) => {
        return response.data;
    })
}

const getAllCompanies = () => {
    return jwtInterceptor.get(API_URL).then((response) => {
        return response.data;
    }).catch(error => {
        console.error('Error:', error);
    });
}

const getProjectByMonogram = (projectMonogram) => {
    return jwtInterceptor.get(`${API_URL}/monogram/${projectMonogram}`).then((response) => {
        LocalStorageService.setCurrentProject(response.data)
        return response.data;
    }).catch(error => {
        console.error('Error:', error);
    });
}

const handleCompanyJoinRequestToProject = (projectId, companyMonogram, username, companyProjectRoleType) => {
    const dto = {
        username: username,
        companyMonogram: companyMonogram,
        projectId: projectId,
        companyProjectRoleType: companyProjectRoleType
    }
    console.log(dto)
    return axios.post(`${API_URL}/join-company`, dto).then((response) => {
        LocalStorageService.setCompanyMonogram(response.data);
        return response.data;
    })
}

// const getUserBoard = () => {
//     return jwtInterceptor.get(API_URL + 'user');
// }
//
// const getProjectAdminBoard = () => {
//     return jwtInterceptor.get(API_URL + 'projectadmin');
// }
//
// const getCompanyAdminBoard = () => {
//     return jwtInterceptor.get(API_URL + 'companyadmin');
// }
// const getAdminBoard = () => {
//     return jwtInterceptor.get(API_URL + 'admin');
// }

const ProjectService = {
    registerNewProjectWithCompanyAndUser,
    getAllCompanies,
    getProjectByMonogram,
    handleCompanyJoinRequestToProject
}

export default ProjectService;
