import {Card} from "@mui/material";
import React from "react";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

const StorageLocationHandler = ({setStorageAt, disabled, storageAt, handleConfirm}) => {
    return (
        <>
            <Card sx={{
                display: "flex",
                flexDirection: 'column',
                alignItems: "flex-start",
                maxWidth: "550px",
                padding: "15px",
                p: 2,
                py: 2,
                margin: "10px auto",
                gap: 2,
                borderRadius: "15px",
                boxShadow: 1
            }} elevation={10}>
                <div>
                    <b> 2. Products will store at: </b>
                </div>
                <FormControl fullWidth>
                    <TextField
                        name="placeOfStorage"
                        id="placeOfStorage"
                        placeholder="Storage Room Name (ex. P1-203)"
                        value={storageAt}
                        onChange={(e) => {
                            if (!disabled) {
                                setStorageAt(e.target.value);
                            }
                        }}
                        disabled={disabled}
                        required
                    />
                </FormControl>
                <div>
                    <Button sx={{ marginRight: "1rem" }} variant="contained" type="submit" onClick={handleConfirm}
                            disabled={storageAt.length === 0 || disabled}>Confirm
                    </Button>
                </div>
            </Card>
            {<div style={{textAlign: "center", color: 'red'}}>The handover process can commence once the storage name is confirmed!</div>}
        </>
    )
}

export default StorageLocationHandler;
