import axios from "axios";
import LocalStorageService from "./localStorageService";
import jwtInterceptor from "./jwtInterceptor";

const API_URL = `${process.env.REACT_APP_BASEURL}/api/v1/companies/join-user`;
const API_USER_URL = `${process.env.REACT_APP_BASEURL}/api/v1/user`;


axios.defaults.withCredentials = true

const registerNewUserCompanyRelation = (username, userJoinCompanyRequest, company2) => {
    const requestData = {
        username: username,
        UserRoleAtCompany: userJoinCompanyRequest.UserRoleAtCompany,
        currentPositionTitleName: userJoinCompanyRequest.currentPositionTitleName,
        userRoleAtCompany: userJoinCompanyRequest.userRoleAtCompany,
        companyId: company2.id
    };
    return jwtInterceptor.post(API_URL, requestData).then((response) => {
        return response.data;
    }).catch(error => {
            console.error('Error:', error);
        });
}
const getCompanyByCurrentUserId = (id) => {
    return jwtInterceptor.get(`${API_USER_URL}/${id}/company`).then((response) => {
        LocalStorageService.setCompanyMonogram(response.data)
        return response.data;
    }).catch(error => {
        console.error('Error:', error);
        return "";
    });
};

const getUserDetailsById = (id) => {
    return axios.get(`${API_USER_URL}/${id}`).then((response) => {
        LocalStorageService.setUserDetails(response.data)
        return response.data;
    }).catch(error => {
        console.error('Error:', error);
    });
};

const UserService = {
    registerNewUserCompanyRelation,
    getCompanyByCurrentUserId,
    getUserDetailsById
}

export default UserService;
