// const URL = `https://api.slingacademy.com/v1/sample-data/products?offset=5&limit=20`
const URL_INCOMING_MATERIALS = `${process.env.REACT_APP_BASEURL}/api/v1/GENERAL_CONTRACTOR/incoming`
const URL_FETCH_FIRST_FOR_HEADER = `${process.env.REACT_APP_BASEURL}/api/v1/GENERAL_CONTRACTOR/header`
const URL_STORED_MATERIALS = `${process.env.REACT_APP_BASEURL}/api/v1/GENERAL_CONTRACTOR/currently-stored`
const URL_RESET = `${process.env.REACT_APP_BASEURL}/api/v1/GENERAL_CONTRACTOR/reset`

export const getHeaderProperties = async () => {
    try {
        const res = await fetch(URL_FETCH_FIRST_FOR_HEADER);

        if (!res.ok) {
            new Error(`GET request failed with status: ${res.status}`);
        }

        return { success: true, data: await res.json() };
    } catch (error) {
        console.error(`GET getHeaderProperties error: ${error.message}`);
        return { success: false, error: error.message };
    }
}

export const getAllIncomingProductList = async () => {
    try {
        const res = await fetch(URL_INCOMING_MATERIALS);

        if (!res.ok) {
            new Error(`GET request failed with status: ${res.status}`);
        }

        return { success: true, data: await res.json() };
    } catch (error) {
        console.error(`GET getAllIncomingProductList error: ${error.message}`);
        return { success: false, error: error.message };
    }
}

export const getAllCurrentlyStoredProductList = async () => {
    try {
        const res = await fetch(URL_STORED_MATERIALS);

        if (!res.ok) {
            new Error(`GET request failed with status: ${res.status}`);
        }

        return { success: true, data: await res.json() };
    } catch (error) {
        console.error(`GET getAllCurrentlyStoredProductList error: ${error.message}`);
        return { success: false, error: error.message };
    }
}
export const resetDatabase = async () => {
    try {
        const res = await fetch(URL_RESET);

        if (!res.ok) {
            new Error(`RESET request failed with status: ${res.status}`);
        }

        return { success: true };
    } catch (error) {
        console.error(`RESET database error: ${error.message}`);
        return { success: false, error: error.message };
    }
}
