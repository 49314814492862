import React, { Component } from "react";

import TestService from "../services/test.service";
import EventBus from "../common/EventBus";
import { withRouter } from '../common/with-router';

class BoardAdmin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: ""
    };
  }

  componentDidMount() {
    TestService.getAdminBoard().then(
      response => {
        this.setState({
          content: response.data
        });
      },
      error => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString(),

        });
        console.log(error)

        if (error.response) {
          if (error.response.status === 403 || error.response.status === 400) {
            this.props.router.navigate("/session")
            EventBus.dispatch("logout");
          }
          EventBus.dispatch("logout");
        }
      }
    );
  }

  render() {
    return (
      <div className="container">
        <header className="jumbotron">
          <h3>{this.state.content}</h3>
        </header>
        <h2>Welcome to the Admin Interface!</h2>
        <p>We're here to make your life easier. Tell us what you need, and we'll bring the magic. 🚀</p>
      </div>
    );
  }
}
export default withRouter(BoardAdmin);
