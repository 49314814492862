import axios from "axios";
import jwtInterceptor from "./jwtInterceptor";

axios.defaults.withCredentials = true;

const API_URL = `${process.env.REACT_APP_BASEURL}/api/v1`;

const getAllProducts = (companyProjectRoleType) => {
    return jwtInterceptor.get(`${API_URL}/${companyProjectRoleType}/all`).then((response) => {
        return response.data;
    }).catch(error => {
        console.error('Error:', error);
    });
}


const ProductService = {
    getAllProducts
}

export default ProductService;
